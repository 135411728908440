<template>
  <div id="nav-header" class="navbar navbar-default navbar-fixed-top">
    <div class="navbar-header">
      <a class="navbar-brand" id="home_link" href="/">NorthStar Portal</a>
    </div>
    <div class="collapse navbar-collapse">
      <b-container fluid style='margin-bottom: 0px; display:block;'>
        <b-row>
          <b-col>
            <ul class="nav navbar-nav navbar-right pull-right">
              <li class="dropdown" v-bind:class="{open: isOpen}">
                <a href="#" class="dropdown-toggle " v-on:click="toggleMenu()">{{userName}}</a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="nav" href="/dashboard/user/profile" title="User Profile">
                      <span class="glyphicon glyphicon-user"></span>
                       Profile
                    </a>
                  </li>
                  <li>

                    <router-link to="/login" v-on:click.native="logout()" replace id="logout" class="nav" title="Logout">
                      <span class="glyphicon glyphicon-log-out"></span>
                      Logout
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import rest from '@/common/tools/Rest';
  import store from '@/store';

  export default {
    name: 'NavigationHeader',
    computed: {
      userName() {
        return store.state.session.user.Contact ? store.state.session.user.Contact.first_name + ' ' + store.state.session.user.Contact.last_name : store.state.session.user.username;
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      logout(){
        rest.logout().catch(e => {
            this.error(rest.getErrorMessage(e));
        });
      },
      toggleMenu(){
        this.isOpen = !this.isOpen;
      },
    }
  }
</script>

<style scoped>
  .navbar-header {
    background: #f1f2f2;
    border-bottom: solid 1px #d1d3d4;
    border-right: solid 1px #d1d3d4;
    height: 44px;
    width: 17vmax;
  }
  .navbar-right .dropdown-toggle {
    height: 43px;
  }
  .dropdown-menu {
    float: left !important;
    position: absolute !important;
  }
</style>
