<template>
  <Form @submit.prevent="handleSubmit" id="contact-form" :tableData='contacts' className='contact' :defaultVisibleOverride='showContact' :tableFields='tableFields' :tableDataActions='tableDataActions' @add='handleAdd' @cancel='hideContact' @clickAction='handleAction' @deleted='load'>
    <!--<ContactEdit v-if='contacts[selectedContactIndex]' ref='contact' :contact='contacts[selectedContactIndex]' @saved='hideContact' :manufacturer_id='manufacturer_id' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'></ContactEdit>-->
    <ContactEdit v-if='contacts[selectedContactIndex]' ref='contact' :contact='contacts[selectedContactIndex]' @saved='hideContact' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'></ContactEdit>
  </Form>
</template>

<script>
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import abilityTester from '@/config/abilityTester';
  import ContactEdit from '@/components/contact/Edit';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import rest from '@/common/tools/Rest';

  export default {
    name: 'Contacts',
    components: {
      Form,
      ContactEdit,
    },
    computed: {
      tableDataActions() {
        var resp = [];
        for (var i in this.contacts) {
          var actions = [];
          actions.push('view');
          if (this.canDeleteContact(this.contacts[i].id)) {
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    created() {
      this.load();
    },
    data() {
      return {
        contacts: [],
        selectedContactIndex: null,
        showContact: false,
        tableFields: [
          {key: 'last_name', label: 'Contact'},
          {key: 'Action', class: 'text-center'}
        ],
      }
    },
    props: {
      manufacturer_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canDeleteContact(id) {
        return abilityTester.can('delete', 'contact', {contact_id: id});
      },
      load() {
        var args = {
          'filter': 'Contacts.status neq INACTIVE',
          'loading': 'Contacts'
        }
        this.$emit('entrypoint_wait_show', 'Loading Contacts...');
        rest.get('manufacturer/' + this.manufacturer_id, 'manufacturer', args).then(response => {
          this.contacts = response.Contacts ? response.Contacts : [];
          this.selectedContactIndex = null;
          this.showContact = false;
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        }).finally(() => {
          this.$emit('entrypoint_wait_hide');
        })
      },
      displayContact(index) {
        this.selectedContactIndex = index;
        this.showContact = true;
      },
      handleAction(action, item, index) {
        switch(action) {
            case 'delete':
                this.displayDeleteModal(item.id);
                break;
            case 'view':
                this.displayContact(index);
                break;
        }
      },
      handleAdd() {
        this.displayContact(this.contacts.push({'id': ''}) - 1);
      },
      handleSubmit() {
        //this.$refs.contact.save();
        var data = JSON.parse(JSON.stringify(this.contacts[this.selectedContactIndex]));
        var saveContact = (data) => {
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('manufacturer/' + this.manufacturer_id + '/contact', 'contact', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.contacts[this.selectedContactIndex], k, val);
            }
            this.$emit('entrypoint_wait_hide');
            this.alertSuccess('Contact Saved');
            this.$emit('saved');
          }).catch(e => {
              this.$emit('entrypoint_wait_hide');
              this.error(rest.getErrorMessage(e));
          })
        }
        saveContact(data);
      },
      hideContact() {
        this.showContact = false;
      },
    },
  }
</script>
