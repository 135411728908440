export default {
  SERVICE: {
    display: 'Service',
    value: 'SERVICE'
  },
  TRANSPORT: {
    display: 'Operator',
    value: 'TRANSPORT'
  }
}
