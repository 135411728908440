<template>
  <span v-if='status' :class='"status-indicator status-" + status' :alt='status' v-b-tooltip hover :title='status'></span>
</template>

<script>
  export default {
    name: 'StatusIndicator',
    data(){
      return {
      }
    },
    props: {
        status: String,
    },
  }
</script>

<style scoped>

.status-indicator {
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border: 1px solid #ccc;
	border-radius: 8px;
	display: inline-block;
	height: 8px;
	position: relative;
	top: -1px;
	width: 8px;
        margin-right: 3px;

}
.status-ACTIVE { background-color: green; border-color: green; }
.status-INACTIVE { background-color: red; border-color: red; }
.status-LOCKED { background-color: blue; border-color: blue; }
.status-SUSPENDED { background-color: #cc00cc; border-color: #cc00cc; }
.status-PENDING { background-color: #33ffcc; border-color: #33ffcc; }
</style>
