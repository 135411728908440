<template>
  <div>
    <form>
        <div>
          <b-row class='mb-5' >
            <FormInput className='systemstats' fieldName='start_date' type='date' v-model='start_date' :canUpdate='true' :singleLine='true'/>
            <FormInput className='systemstats' fieldName='end_date' type='date' v-model='end_date' :canUpdate='true' :singleLine='true'/>
          </b-row>
          <div class='search-row clearfix' >
            <button type='button' class='pull-right btn btn-xs btn-primary' @click.prevent='search'>Search</button>
          </div>
          <b-row class='mb-5'>
            <b-col v-if='systemstatsIsEmpty'>No results for selected dates.</b-col>
            <highcharts v-else :options="chartOptions" style='margin: auto; width: 90%; padding-right: 25px;'></highcharts>
          </b-row>
        </div>
    </form>
  </div>
</template>

<script>
  import rest from '@/common/tools/Rest';
  import abilityTester from '@/config/abilityTester';
  import {Chart} from 'highcharts-vue';
  import FormInput from '@/components/form/Input';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import Utils from '@/common/tools/Utils';

  export default {
    name: 'Systemstats',
    components:{
      FormInput,
      highcharts: Chart,
    },
    computed: {
      chartOptions() {
        var seriesToggleInstructions = 'Click the labels below the plot area to toggle data visibilty';
        var options = {
          chart: {
              zoomType: 'x'
          },
          credits: {
            enabled: false
          },
          series: [],
          subtitle: {
              text: document.ontouchstart === undefined ?
                  'Click and drag in the plot area to zoom in<br>' + seriesToggleInstructions : 'Pinch the chart to zoom in<br>' + seriesToggleInstructions
          },
          title: {
              text: 'System Stats'
          },
          xAxis: {
              type: 'datetime',
              dateTimeLabelFormats: {
                  day: '%b %e \'%y',
                  week: '%b %e \'%y',
              },
              title: {
                  text: 'Date'
              }
          },
          yAxis: {
              title: {
                  text: 'Count'
              },
              min: 0
          },
        }
        var activeSubscriptions = {};
        var newSubscriptions = {};
        var otherSeries = {};
        for(var i in this.systemstats){
          var stat = JSON.parse(JSON.stringify(this.systemstats[i]));
          var date = stat.date;
          if(!date){
            continue;
          }
          var dateTimestamp = Utils.date.format.mdyToDateUTC(date);
          var removeFields = ['id', 'created_at', 'updated_at', 'date', 'status', 'provider_id'];
          for(var j in removeFields){
            delete stat[removeFields[j]];
          }
          // Add active and new subscriptions
          this.addSubscriptionsToSeries(stat.active_subscriptions, activeSubscriptions, dateTimestamp, 'Active Subscriptions');
          this.addSubscriptionsToSeries(stat.new_subscriptions, newSubscriptions, dateTimestamp, 'New Subscriptions', true);
          delete stat['active_subscriptions'];
          delete stat['new_subscriptions'];

          // Add remaining fields
          for(var field in stat){
            this.addToSeries(otherSeries, dateTimestamp, field, stat[field]);
          }
        }
        this.addSeriesToOptions(options, activeSubscriptions);
        this.addSeriesToOptions(options, newSubscriptions);
        this.addSeriesToOptions(options, otherSeries);
        return options;
      },
      systemstatsIsEmpty(){
        return Object.keys(this.systemstats).length === 0;
      }
    },
    created(){
        this.setDefaults();
        this.search();
    },
    data() {
      return {
        end_date: '',
        start_date: '',
        systemstats: []
      }
    },
    props: {
      provider_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      },
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      addToSeries(series, dateTimestamp, field, count, seriesDescription = ''){
        if(!series[field]){
          var newSeries = {};
          newSeries[field] = {
            data: [],
            name: Utils.string.ucwords(field, '_') + ' ' + seriesDescription
          }
          Object.assign(series, newSeries);
        }
        series[field]['data'].push([dateTimestamp, count]);
      },
      addSubToSeries(series, dateTimestamp, addedAccountTypes, accountType, count, seriesDescription = ''){
        this.addToSeries(series, dateTimestamp, accountType, count, seriesDescription)
        addedAccountTypes.push(accountType);
      },
      addSubscriptionsToSeries(stat, series, dateTimestamp, seriesDescription = '', addValueIfEmpty = false){
        var addedAccountTypes = [];
        var total = 0;
        var accountTypeTotal = 'total';
        for(var accountType in stat){
          var subs = stat[accountType];
          if(!subs.count || isNaN(Number(subs.count))){
            continue;
          }
          subs.count = Number(subs.count);
          this.addSubToSeries(series, dateTimestamp, addedAccountTypes, accountType, subs.count, seriesDescription);
          total += subs.count;
        }
        if(!series[accountTypeTotal]){
        this.addSubToSeries(series, dateTimestamp, addedAccountTypes, accountTypeTotal, total, seriesDescription);
        }
        series[accountTypeTotal]['data'].push([dateTimestamp, total]);
        if(addValueIfEmpty){
          for(accountType in series){
            if(accountType == accountTypeTotal || addedAccountTypes.includes(accountType)){
              continue;
            }
            series[accountType]['data'].push([dateTimestamp, 0]);
          }
        }
      },
      addSeriesToOptions(options, subscriptions){
        if(Object.keys(subscriptions).length > 0) {
          for(var i in subscriptions){
            options.series.push(subscriptions[i]);
          }
        }
      },
      canReadField: function(field) {
        return abilityTester.can('read', 'systemstats', {provider_id: this.provider.id}, field);
      },
      canUpdateProvider: function(field) {
        return this.canUpdate(this.provider.id, field);
      },
      canUpdate(provider_id,field){
        return abilityTester.can('update', 'systemstats', {provider_id: provider_id}, field);
      },
      search(){
        var args = {
          'start_date': Utils.date.format.ymdToMdy(this.start_date),
          'end_date': Utils.date.format.ymdToMdy(this.end_date),
        }
        this.$emit('entrypoint_wait_show', 'Loading Systemstats...');
        rest.get('provider/' + this.provider_id + '/Systemstats/search','systemstats', args).then(response => {
            this.systemstats = response ? response : [];
          }).catch(e=>{
            this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      setDefaults(){
        this.$set(this, 'start_date', Utils.date.format.ymd(Utils.date.subtract(new Date(), 14)));
        this.$set(this, 'end_date', Utils.date.format.ymd(new Date()));
      },
    },
    mounted(){
    },
  }
</script>

<style scoped>
  .search-row {
    margin: 0px;
  }
  .search-row > button {
    margin-left: 5px;
  }
</style>
