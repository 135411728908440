<template>
  <div class='alert-container'>
    <div v-for='(message, i) in messages' v-bind:key='i'>
      <AlertError v-if='message.type == "error"' :message='message.msg' @dismissed='removeError(i)'/>
      <AlertSuccess v-else-if='message.type == "success"' :message='message.msg' @dismissed='removeSuccess(i)'/>
    </div>
  </div>
</template>

<script>
  import AlertError from '@/components/alerts/Error';
  import AlertSuccess from '@/components/alerts/Success';
  import {REMOVE_ERROR, REMOVE_SUCCESS} from '@/store/MutationTypes';
  import { mapMutations } from 'vuex';
  import store from '@/store';

  export default {
    name: 'AlertContainer',
    components: {
      AlertError,
      AlertSuccess,
    },
    data() {
      return {}
    },
    computed: {
      messages(){
        var errors = store.state.messages.errors ? store.state.messages.errors : {};
        var successes = store.state.messages.successes ? store.state.messages.successes : {};
        var msgs = {}
        for(var k in errors){
          msgs[k] = {type: 'error', msg: errors[k]};
        }
        for(k in successes){
          msgs[k] = {type: 'success', msg: successes[k]};
        }
        return msgs;
      },
    },
    methods: {
      ...mapMutations('messages', {
        removeError: REMOVE_ERROR,
        removeSuccess: REMOVE_SUCCESS
      }),
    }
  }
</script>

<style scoped>
  .alert-container{
      position: fixed;
      top: 0;
      right: 0;
      z-index: 9999;
  }
</style>
