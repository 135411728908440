import Vue from 'vue'
import Router from 'vue-router'
import LoginView from '@/views/Login';
import DashboardView from '@/views/Dashboard';
import NotFoundView from '@/views/error/NotFound';
import ProviderListView from '@/views/provider/List';
import ProviderBillingView from '@/views/provider/Billing';
import UserListView from '@/views/user/List';
import UserProfile from '@/views/user/Profile';
import ManufacturerListView from '@/views/manufacturer/List';
import SignatureListView from '@/views/signature/List';
import VersionListView from '@/views/version/List';
import store from '@/store';
import userRoles from '@/common/Enums/UserRole';
import '@/assets/css/forms.css';
import '@/assets/css/global.css';

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if(!store.getters['session/isAuthenticated']){
    next();
    return;
  }
  next('/');
}

const ifAuthenticated = (to, from, next) => {
  if(store.getters['session/isAuthenticated']) {
    next();
    return;
  }
  next('/login');
}
const defaultPage = () => {
  var name = 'userProfile';
  var userRole = store.state.session.user ? store.state.session.user.role : '';
  switch(userRole){
    case userRoles.ADMIN.value:
    case userRoles.SALES.value:
      name = 'providerList';
      break;
  }
  return {name: name};
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {
        name: 'dashboard'
      },
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: ifAuthenticated,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: DashboardView,
      redirect: defaultPage,
      children: [
        {
          path: "provider/list",
          name: "providerList",
          component: ProviderListView
        },
        {
          path: "provider/billing",
          name: "providerBilling",
          component: ProviderBillingView
        },
        {
          path: "user/list",
          name: "userList",
          component: UserListView
        },
        {
          path: "user/profile",
          name: 'userProfile',
          component: UserProfile
        },
        {
          path: "manufacturer/list",
          name: "manufacturerList",
          component: ManufacturerListView
        },
        {
          path: "signature/list",
          name: "signatureList",
          component: SignatureListView
        },
        {
          path: "version/list",
          name: "versionList",
          component: VersionListView
        },
      ],

    },
    {
      path: "*",
      component: NotFoundView
    }
  ]
})
