<template>
  <div>
    <b-row class='mb-5' >
      <FormInput className='manufacturer' fieldName='created_at' :value='manufacturer.created_at' :canUpdate='false' :singleLine='true'/>
      <FormInput className='manufacturer' fieldName='updated_at' :value='manufacturer.updated_at' :canUpdate='false' :singleLine='true'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput className='manufacturer' fieldName='id' :value='manufacturer.id' :canUpdate='false'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("name")' className='manufacturer' fieldName='name' v-model='manufacturer.name' :canUpdate='canUpdateManufacturer("name")'/>
      <FormInput v-if='canReadField("acronym")' className='manufacturer' fieldName='acronym' v-model='manufacturer.acronym' :canUpdate='canUpdateManufacturer("acronym")'/>
    </b-row>
    <b-row class='mb-5' >
      <FormSelect v-if='canReadField("status")' className='manufacturer' fieldName='status' v-model='manufacturer.status' :canUpdate='canUpdateManufacturer("status")' :options='statuses'/>
    </b-row>
  </div>
</template>

<script>
  import rest from '@/common/tools/Rest';
  import abilityTester from '@/config/abilityTester';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import statusesEnum from '@/common/Enums/Status';

  export default {
    name: 'ManufacturerEdit',
    components:{
      FormInput,
      FormSelect,
    },
    computed: {
    },
    created(){
        this.setDefaults();
    },
    data() {
      return {
        statuses: statusesEnum
      }
    },
    props: {
      manufacturer: {}
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      _sortKeys(obj){
        if(typeof obj !== 'object' || Array.isArray(obj)){
          return obj;
        }
        var ordered = {};
        Object.keys(obj).sort().forEach((k) => {
          ordered[k] = this._sortKeys(obj[k]);
        });
        return ordered;
      },
      canReadField: function(field) {
        return abilityTester.can('read', 'manufacturer', {manufacturer_id: this.manufacturer.id}, field);
      },
      canUpdateManufacturer: function(field) {
        return this.canUpdate(this.manufacturer.id, field);
      },
      canUpdate(manufacturer_id,field){
        return abilityTester.can('update', 'manufacturer', {manufacturer_id: manufacturer_id}, field);
      },
      save() {
        var data = JSON.parse(JSON.stringify(this.manufacturer));
        var saveManufacturer = (data) => {
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('manufacturer', 'manufacturer', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.manufacturer, k, val);
            }
            this.ManufacturerChanged();
            this.alertSuccess('Manufacturer Saved');
          }).catch(e => {
              this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
        }

        saveManufacturer(data);
      },
      ManufacturerChanged(){
        this.$emit('UpdateManufacturer', this.manufacturer);
      },
      setDefaults(){
        if(!this.manufacturer.status){
          this.$set(this.manufacturer,'status', this.statuses.ACTIVE.value);
        }
      },
      updateConfig(newConfig){
        this.manufacturer['configuration_json'] = JSON.stringify(newConfig);
        this.ManufacturerChanged();
      },
    },
    mounted(){
    },
    watch: {
      manufacturer: function(){
        this.setDefaults();
      }
    },
  }
</script>
