<template>
  <div>
    <SearchHeader title='Manufacturers' icon='cubes' prompt='Search for a manufacturer by providing a name, description, or other identifying details.' @change='search'/>
    <SearchResults v-if='searchText || manufacturers.length > 0' :data='manufacturers && Array.isArray(manufacturers) ? manufacturers : []' className='manufacturer' :fields='tableFields' :actions='tableActions ? tableActions : []' :dataActions='tableDataActions  ? tableDataActions : []' @clickAction='handleAction'/>
    <b-row v-if='canCreateManufacturer()'>
      <b-col>
        <b-button size='xs' variant='primary' @click='addManufacturer()'>Add Manufacturer</b-button>
      </b-col>
    </b-row>
    <DeleteRecordModal ref='deleteModal' className='manufacturer' :id='deleteId' @deleted='search(searchText)'/>
    <TabbedModal v-if='manufacturers[selectedManufacturerIndex]' id='manufacturer_modal' :visible='showManufacturerModal' @change='handleHideModal' title='Manage Manufacturer' @input='setSelectedTab'>
      <Tab title='Details'>
        <Form @submit="handleSubmit" id='manufacturer-form' :saveVisibleOverride='canUpdateManufacturer()'>
          <ManufacturerEdit ref='manufacturer' @UpdateManufacturer='UpdateManufacturer' :manufacturer="manufacturers[selectedManufacturerIndex]" @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
        </Form>
      </Tab>
      <Tab v-if='canReadMacAddresses()' title='MAC Addresses' :disabled='selectedManufacturerIsNew'>
        <MacAddressList ref='macAddresses' :manufacturer_id="selectedManufacturerId" v-if='!selectedManufacturerIsNew && selectedTab == 1' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
      </Tab>
      <Tab title='Contacts'>
        <ContactList ref='contacts' :manufacturer_id="selectedManufacturerId" v-if='!selectedManufacturerIsNew && selectedTab == 2' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
      </Tab>
      <Tab title='Products'>
        <ProductList ref='products' :manufacturer_id="selectedManufacturerId" v-if='!selectedManufacturerIsNew && selectedTab == 3' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
      </Tab>
    </TabbedModal>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import MacAddressList from '@/components/manufacturer/MacAddresses';
  import ContactList from '@/components/manufacturer/Contact';
  import ProductList from '@/components/manufacturer/Product';
  import DeleteRecordModal from '@/components/modal/DeleteRecord';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import TabbedModal from '@/components/modal/TabbedModal';
  import rest from '@/common/tools/Rest';
  import SearchHeader from '@/components/search/Header';
  import SearchResults from '@/components/search/Results';
  import ManufacturerEdit from '@/components/manufacturer/Edit';
  import Tab from '@/components/elements/tabs/Tab';

  export default {
    name: 'ManufacturerList',
    components: {
      MacAddressList,
      ContactList,
      ProductList,
      DeleteRecordModal,
      Form,
      TabbedModal,
      SearchHeader,
      SearchResults,
      ManufacturerEdit,
      Tab,
    },
    computed: {
      selectedManufacturerId(){
        return this.manufacturers[this.selectedManufacturerIndex] && this.manufacturers[this.selectedManufacturerIndex].id ? this.manufacturers[this.selectedManufacturerIndex].id : '';
      },
      selectedManufacturerIsNew() {
        return  this.selectedManufacturerId ? false : true
      },
      showManufacturerModal() {
        return this.manufacturers[this.selectedManufacturerIndex] ? true : false;
      },
      tableDataActions() {
        var resp = [];
        for(var i in this.manufacturers){
          var actions = [];
          actions.push('view');
          if(this.canDeleteManufacturer(this.manufacturers[i].id)){
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    data() {
      return {
        deleteId: '',
        searchText: '',
        selectedManufacturerIndex: null,
        selectedTab: null,
        tableActions: {
          delete: {title: 'Delete', icon: 'times'},
          view: {title: 'View', icon: 'edit'}
        },
        tableFields: [
          {key: 'name', displayStatus: true},
          {key: 'Action', class: 'text-center'}
        ],
        manufacturers: []
      }
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      addManufacturer(){
        var newManufacturer = {};
        var i = this.manufacturers.push(newManufacturer) - 1;
        this.displayManufacturerModal(i);
      },
      canCreateManufacturer(){
        return abilityTester.can('create', 'manufacturer');
      },
      canDeleteManufacturer(id){
        return abilityTester.can('delete', 'manufacturer', {manufacturer_id: id});
      },
      canReadMacAddresses(id){
        return abilityTester.can('read', 'Manufacturermacaddress', {manufacturer_id: id});
      },
      canUpdateManufacturer(){
        return this.$refs.manufacturer ? this.$refs.manufacturer.canUpdateManufacturer() : ManufacturerEdit.methods.canUpdate(this.manufacturers[this.selectedManufacturerIndex]);
      },
      displayDeleteModal(id){
        this.deleteId = id;
        this.$refs.deleteModal.show();
      },
      displayManufacturerModal(index){
        this.selectedManufacturerIndex = index;
      },
      handleAction(action, item, index){
        switch(action){
          case 'delete':
            this.displayDeleteModal(item.id);
            break;
          case 'view':
            this.displayManufacturerModal(index);
            break;
        }

      },
      handleHideModal(show){
        if(!show){
          this.selectedManufacturerIndex = null;
          this.setSelectedTab(0);
        }
      },
      handleSubmit(){
        this.$refs.manufacturer.save();
      },
      search(text){
        if(text.length === 0){
          this.searchText = '';
          this.manufacturers = [];
          return;
        }
        this.selectedManufacturerIndex = null;
        this.searchText = text;
        var args = {
          'loading': 'Macaddresses'
        }
        this.$emit('entrypoint_wait_show', 'Searching...');
        rest.get('manufacturer/search/' + text, 'manufacturer', args).then(manufacturers => {
          this.manufacturers = Array.isArray(manufacturers) ? manufacturers : [];
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      setSelectedTab(value){
        this.selectedTab = value;
      },
      UpdateManufacturer(manufacturer) {
        this.manufacturers[this.selectedManufacturerIndex] = manufacturer;
        this.$refs.manufacturer.$forceUpdate();
      },
    }
  }
</script>

<style scoped>
</style>
