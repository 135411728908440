<template>
  <Modal ref='modal' id='deleteRecordModal' v-model='display' title='Delete Record' title-tag='h4' button-size='xs' cancel-variant='default' ok-title='Delete' @ok="deleteRecord">
    <p>
      <font-awesome-icon icon='exclamation-triangle'/>
      This item will be deleted.
    </p>
    <p>
    Are you sure you want to continue?
    </p>
  </Modal>
</template>

<script>
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import Modal from '@/components/modal/Modal';
  import rest from '@/common/tools/Rest';

  export default {
    name: 'DeleteRecordModal',
    components: {
      Modal
    },
    data(){
      return {
        display: false
      }
    },
    props: {
      className: String,
      queryString: String,
      id: String
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      deleteRecord (e) {
        e.preventDefault();
        var query = this.queryString ? '?' + this.queryString : '';
        rest.delete(this.className + '/' + this.id + query, this.className).then(() => {
          this.$refs.modal.hide();
          this.$emit('deleted',this.id);
        }).catch(e => {
            this.error(rest.getErrorMessage(e));
        });
      },
      show() {
        this.display = true;
        this.$refs.modal.show();
      },
    }
  }
</script>

<style scoped>
</style>
