export default {
  PROVINCE: {
    display: 'Province',
    value: 'PROV'
  },
  PROVINCE_TERRITORY: {
    display: 'Province/Territory',
    value: 'PROV_TER'
  },
  STATE: {
    display: 'State',
    value: 'STATE'
  },
  STATE_TERRITORY: {
    display: 'State/Territory',
    value: 'STATE_TER'
  },
  TERRITORY: {
    display: 'Territory',
    value: 'TER'
  },
};
