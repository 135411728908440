<template>
  <DynamicForm :value="configuration" @input='updateConfig($event)' :canUpdate='canUpdate' className='provider-configuration' :required='false' :allowAddRemove='allowAddRemove'/>
</template>

<script>
  import DynamicForm from '@/components/form/Dynamic';

  export default {
    name: 'ProviderAllConfig',
    components:{
      DynamicForm,
    },
    props: {
      allowAddRemove: {
        default: false,
        type: Boolean
      },
      canUpdate: {
        default: false,
        type: Boolean
      },
      configuration: {}
    },
    methods: {
      updateConfig(newConfig){
        this.$emit('updateConfig', newConfig);
      },
    },
  }
</script>
