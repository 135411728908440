<template>
  <div>
    <b-row class='mb-5' >
      <FormInput className='provider' fieldName='created_at' :value='provider.created_at' :canUpdate='false' :singleLine='true'/>
      <FormInput className='provider' fieldName='updated_at' :value='provider.updated_at' :canUpdate='false' :singleLine='true'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput className='provider' fieldName='id' :value='provider.id' :canUpdate='false'/>
      <FormInput className='provider' fieldName='flowops_version' :value='provider.software_versions.FLOWOPS_API' :canUpdate='false'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("name")' className='provider' fieldName='name' label='Provider Name' v-model='provider.name' :canUpdate='canUpdateProvider("name")'/>
      <FormSelect v-if='canReadField("type")' className='provider' fieldName='type' v-model='provider.type' :canUpdate='false' @change='setupConfigs()' :options='providerTypes'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("customer_number")' className='provider' fieldName='customer_number' label='Customer Number' v-model='provider.customer_number' :canUpdate='canUpdateProvider("customer_number")'/>
      <FormSelect v-if='canReadField("status")' className='provider' fieldName='status' v-model='provider.status' :canUpdate='canUpdateProvider("status")' :options='statuses'/>
    </b-row>
    <b-row class='mb-5' >
      <FormTextArea v-if='canReadField("description")' className='provider' fieldName='description' v-model='provider.description' :canUpdate='canUpdateProvider("description")' placeholder='Brief Description' size='12' rows='3' :required='false'/>
    </b-row>
    <Tabs style='margin: 10px;'>
      <Tab v-if='canReadField("Location")' title='Location'>
        <LocationEdit :location='provider.Location' @UpdateLocation='UpdateLocation' className='provider'/>
      </Tab>
      <Tab v-if='canReadCustomConfig' title='Custom Configs' v-bind:disabled="Object.keys(configurationTemplate).length <= 0">
        <ProviderConfigCustom ref='configCustom' :configuration="configuration" :configurationTemplate="configurationTemplate" @updateConfig='updateConfig' :canUpdate='canUpdateCustomConfig' :allowAddRemove='canUpdateCustomConfig' className='provider-configuration' :providerType='provider.type' :required='false'/>
      </Tab>
      <Tab v-if='canReadDetailedConfig' title='Detailed Configs' v-bind:disabled="Object.keys(configurationTemplate).length <= 0">
        <ProviderConfigAll ref='configAll' :configuration="configuration" @updateConfig='updateConfig($event)' :canUpdate='canUpdateDetailedConfig' :allowAddRemove='canUpdateDetailedConfig' className='provider-configuration' :required='false'/>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
  import rest from '@/common/tools/Rest';
  import abilityTester from '@/config/abilityTester';
  import DomainURLMappingService from '@/config/provider/templates/custom/DomainURLMappingService';
  import DomainURLMappingTransport from '@/config/provider/templates/custom/DomainURLMappingTransport';
  import DynamicForm from '@/components/form/Dynamic';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import FormTextArea from '@/components/form/TextArea';
  let generator = require('generate-password');
  import LocationEdit from '@/components/location/Edit';
  import locationTypesEnum from '@/common/Enums/LocationType';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import ProviderConfigAll from '@/components/provider/config/All';
  import ProviderConfigCustom from '@/components/provider/config/Custom';
  import providerTypesEnum from '@/common/Enums/ProviderType';
  import pocStatusesEnum from '@/common/Enums/PocStatus';
  import statusesEnum from '@/common/Enums/Status';
  import Tab from '@/components/elements/tabs/Tab';
  import Tabs from '@/components/elements/tabs/Tabs';
  import templateService from '@/config/provider/templates/LocalService';
  import templateTransport from '@/config/provider/templates/LocalTransport';
  import Utils from '@/common/tools/Utils';

  export default {
    name: 'ProviderEdit',
    components:{
      ProviderConfigAll,
      ProviderConfigCustom,
      FormInput,
      FormSelect,
      FormTextArea,
      LocationEdit,
      Tab,
      Tabs,
    },
    computed: {
      canReadCustomConfig(){
        return this.canReadField("configuration_json") && abilityTester.can('read', 'provider-custom_configuration', {provider_id: this.provider.id});
      },
      canUpdateCustomConfig(){
        return this.canUpdateProvider("configuration_json") && abilityTester.can('update', 'provider-custom_configuration', {provider_id: this.provider.id});
      },
      canReadDetailedConfig(){
        return this.canReadField("configuration_json") && abilityTester.can('read', 'provider-detailed_configuration', {provider_id: this.provider.id});
      },
      canUpdateDetailedConfig(){
        return this.canUpdateProvider("configuration_json") && abilityTester.can('update', 'provider-detailed_configuration', {provider_id: this.provider.id});
      },
      configurationTemplate(){
        var template = {}
        switch(this.provider.type){
          case this.providerTypes.SERVICE.value:
            template = JSON.parse(JSON.stringify(templateService));// Don't modify original array
            break;
          case this.providerTypes.TRANSPORT.value:
            template = JSON.parse(JSON.stringify(templateTransport));// Don't modify original array
            break;
        }
        return template;
      },
      configuration() {
        try {
          var config = this.provider && this.provider.configuration_json ? JSON.parse(this.provider.configuration_json) : {};
          return this._sortKeys(config);
        } catch(err){
          return {}; // If JSON is invalid return empty object
        }
      },
      domainURLMapping(){
        let mapping = {};
        switch(this.provider.type){
          case providerTypesEnum.SERVICE.value:
            mapping = DomainURLMappingService;
            break;
          case providerTypesEnum.TRANSPORT.value:
          default:
            mapping = DomainURLMappingTransport;
        }
        return JSON.parse(JSON.stringify(mapping)); // Don't modify original array
      },
    },
    async created(){
      try {
        this.$emit('entrypoint_wait_show','Loading...')
        this.setDefaults();
        await this.setEntryPointIpv6Base();
        await this.updateNextIpv6Suffix();
        this.setupConfigs();
      } catch(e) {
        this.error(rest.getErrorMessage(e));
      }
      this.$emit('entrypoint_wait_hide');
    },
    data() {
      return {
        ipv6_entrypoint_base: '',
        locationTypes: locationTypesEnum,
        nextIpv6Suffix: '',
        pocStatuses: pocStatusesEnum,
        providerTypes: providerTypesEnum,
        statuses: statusesEnum
      }
    },
    props: {
      provider: {}
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      _addMissingConfigFields(config, template){
        for(var k in template){
          if(config[k] === undefined){
            config[k] = template[k];
          } else if(typeof config[k] == 'object' && typeof template[k] == 'object') {
            config[k] = this._addMissingConfigFields(config[k], template[k]);
          }
        }
        return config;
      },
      _getRandom(options = {}){
        if(options.length === undefined){
          options.length = 18;
        }
        if(options.numbers === undefined){
          options.numbers = true;
        }
        if(options.strict === undefined){
          options.strict = true;
        }
        return generator.generate(options);
      },
      _setGeneratedConfigFields(config){
        for(var k in config){
          if(typeof config[k] == 'object') {
            config[k] = this._setGeneratedConfigFields(config[k]);
          } else if(config[k] === ''){
            if(DynamicForm.methods.isRandomField(k) || k.toLowerCase().indexOf('username') >= 0){
              config[k] = this._getRandom();
            }
          }
        }
        return config;
      },
      _sortKeys(obj){
        if(typeof obj !== 'object' || Array.isArray(obj)){
          return obj;
        }
        var ordered = {};
        Object.keys(obj).sort().forEach((k) => {
          ordered[k] = this._sortKeys(obj[k]);
        });
        return ordered;
      },
      addMissingConfigFields(){
          var newConfig = this._addMissingConfigFields(this.configuration, this.configurationTemplate);
          this.setIpv6(newConfig, false);
          this.setUrlsForDomain(newConfig, false)
          this.updateConfig(newConfig);
      },
      canReadField: function(field) {
        return abilityTester.can('read', 'provider', {provider_id: this.provider.id}, field);
      },
      canUpdateProvider: function(field) {
        return this.canUpdate(this.provider.id, field);
      },
      canUpdate(provider_id,field){
        return abilityTester.can('update', 'provider', {provider_id: provider_id}, field);
      },
      getIpv6Base(config) {
        return config.networks && config.networks.ipv6_suffix ? this.ipv6_entrypoint_base + config.networks.ipv6_suffix : '';
      },
      save() {
        var data = JSON.parse(JSON.stringify(this.provider));
        var saveProvider = (data) => {
          data['loading'] = 'Location';
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('provider', 'provider', data).then(response => {
            for(var k in response){
              var val = response[k];
              switch(k){
                case 'Location':
                  val = val ? val : {};
                  break;
              }
              this.$set(this.provider, k, val);
            }
            this.ProviderChanged();
            this.alertSuccess('Provider Saved');
          }).catch(e => {
              this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
        }
        var location = data.Location;
        delete data.Location;

        if(location && this.canUpdateProvider('Location')) {
          rest.save('location', 'location', location, 'provider-location').then(resp =>{
              if(resp.id){
                data.location_id = resp.id;
              }
              saveProvider(data);
            }
          ).catch(e=>{
            this.error(rest.getErrorMessage(e));
          })
        } else {
          saveProvider(data);
        }
      },
      ProviderChanged(){
        this.$emit('UpdateProvider', this.provider);
      },
      setDefaults(){
        if(!this.provider.Location){
          this.$set(this.provider, 'Location', {});
        }
        if(!this.provider.status){
          this.$set(this.provider,'status', this.statuses.ACTIVE.value);
        }
      },
      async setEntryPointIpv6Base(){
        var response = await rest.get('Ipv6suffix/entrypointbase', 'ipv6suffix')
        this.ipv6_entrypoint_base = response.ipv6_entrypoint_base;
      },
      setGeneratedConfigFields(){
          var newConfig = this._setGeneratedConfigFields(this.configuration);
          this.updateConfig(newConfig);
      },
      setIpv6(config = null, overwrite = false){
        if(!config){
          config = this.configuration;
        }
        if(config.networks && this.ipv6_entrypoint_base) {
          var configChanged = false;
          if(!config.networks.ipv6_suffix){
            Utils.setObjectValueByPath(config, 'networks.ipv6_suffix', this.nextIpv6Suffix);
            overwrite = true;
            configChanged = true;
          }
          if(!config.networks.ipv6_suffix){
            return
          }
          var ipv6Base = this.getIpv6Base(config);
          var changed = false;
          for(var k in config.networks){
            if(k.endsWith('_network')) {
              if(config.networks[k].details){
                changed = this.setIpv6FromIpv4(config.networks[k].details, ipv6Base, 'gateway', overwrite);
                configChanged = configChanged || changed;
              }
              if(config.networks[k].reserved){
                for(var j in config.networks[k].reserved){
                  changed = this.setIpv6FromIpv4(config.networks[k].reserved[j], ipv6Base, 'ip_address', overwrite);
                  configChanged = configChanged || changed;
                }
              }
            }
          }
          if(configChanged){
            this.updateConfig(config);
          }
        }
      },
      setIpv6FromIpv4(config, ipv6Base, key = 'ip_address', overwrite = true){
        if(!ipv6Base){
          return false;
        }
        var path = key;
        path = path.split('.');
        key = path.pop();
        path = path.join('.');
        var childConfig = path ? Utils.getObjectValueByPath(config, path, {}) : config;
        var ipv6Key = key == 'ip_address' ? 'ipv6_address' : 'ipv6_' + key;
        if(childConfig[key]){
          if(childConfig[key].substring(0,4) == 'ref:'){
            if(childConfig[key].endsWith('/ip_address')) {
              childConfig[ipv6Key] = childConfig[key].substring(0, childConfig[key].length - 10) + 'ipv6_address';
            } else if(childConfig[key].endsWith('/' + key)) {
              childConfig[ipv6Key] = childConfig[key].substring(0, childConfig[key].length - key.length) + ipv6Key;
            }
          } else if(overwrite || !childConfig[ipv6Key]) {
            childConfig[ipv6Key] = Utils.ipv4ToIpv6(childConfig[key], ipv6Base);
          } else {
            return false;// Don't overwrite existing values
          }
          if(path){
            Utils.setObjectValueByPath(config, path, childConfig);
            return true;
          }
        }

        return false;
      },
      setSubscriberPortalUrl(newConfig, newHostName, domain = ''){
        if(!newHostName){
          newHostName = Utils.urls.toDomain(Utils.getObjectValueByPath(this.configuration, 'local.urls.subscriber_url'));
        }
        if(!domain){
          domain = Utils.getObjectValueByPath(this.configuration, 'local.constants.domain');
        }
        let oldSubscriberUrl = Utils.getObjectValueByPath(this.configuration, 'local.urls.subscriber_url');
        let subscriberLandingUrl = Utils.getObjectValueByPath(this.configuration, 'local.urls.subscriber_landing_url');
        let subscriberUrl = 'https://' + newHostName + '.' + domain;
        if(!subscriberLandingUrl || subscriberLandingUrl == oldSubscriberUrl){
          Utils.setObjectValueByPath(newConfig, 'local.urls.subscriber_landing_url', subscriberUrl);
        }
        Utils.setObjectValueByPath(newConfig, 'local.urls.subscriber_url', subscriberUrl);
      },
      setupConfigs(){
        this.addMissingConfigFields();
        this.setGeneratedConfigFields();

      },
      setUrlsForDomain(newConfig, overwriteValues = true){
        var domain = Utils.getObjectValueByPath(newConfig, 'local.constants.domain')
        for(let domainUrl of this.domainURLMapping){
          if(!domainUrl.hostname || !domainUrl.path || (!overwriteValues && Utils.getObjectValueByPath(newConfig, domainUrl.path))){
            continue;
          }
          let newUrl = domainUrl.hostname + '.' + domain;
          if(domainUrl.includeProtocol === undefined || domainUrl.includeProtocol){
            newUrl = 'https://' + newUrl;
          }
          Utils.setObjectValueByPath(newConfig, domainUrl.path, newUrl);
        }
      },
      updateConfig(newConfig){
        var path = '';
        if(newConfig.path && newConfig.value){
          path = newConfig.path;
          newConfig = newConfig.value;
        }
        var value = '';
        switch(path) {
          case'networks.ipv6_suffix':
            this.setIpv6(newConfig, true);
            break;
          case 'local.constants.domain':
            value = Utils.getObjectValueByPath(newConfig, path)
            this.setSubscriberPortalUrl(newConfig, null, value)
            this.setUrlsForDomain(newConfig)
            break;
          case 'subscriber_portal_hostname':
            value = Utils.getObjectValueByPath(newConfig, path)
            this.setSubscriberPortalUrl(newConfig, value)
            delete newConfig.subscriber_portal_hostname
            break;
          default:
            if(path.endsWith('.gateway') || path.endsWith('ip_address')) {
              this.setIpv6FromIpv4(newConfig, this.getIpv6Base(newConfig), path);
            }
        }
        this.provider['configuration_json'] = JSON.stringify(newConfig);
        this.ProviderChanged();
      },
      UpdateLocation(newLocation){
        this.$set(this.provider, 'Location', newLocation);
        var config = this.configuration;
        var configChanged = false;
        if(newLocation.state){
          Utils.setObjectValueByPath(config, 'crypto.state_code', newLocation.state);
          Utils.setObjectValueByPath(config, 'local.entrypoint_communicator.state_code', newLocation.state);
          configChanged = true;
        }
        if(newLocation.country){
          Utils.setObjectValueByPath(config, 'crypto.country_code', newLocation.country);
          Utils.setObjectValueByPath(config, 'local.entrypoint_communicator.country_code', newLocation.country);
          configChanged = true;
        }
        if(configChanged){
          this.updateConfig(config);
        }
        this.ProviderChanged();
      },
      async updateNextIpv6Suffix(){
        var response = await rest.get('ipv6suffix/next', 'ipv6suffix')
        this.nextIpv6Suffix = response.suffix;
      }
    },
    watch: {
      provider: function(){
        this.setDefaults();
      }
    },
  }
</script>
