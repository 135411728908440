<template>
  <Alert ref="alert" :header="header" :variant="variant" :icon="icon" :message="msg" @dismissed='$emit("dismissed")' :dismissible='true'/>
</template>

<script>
  import Alert from '@/components/alerts/Alert'

  export default {
    name: 'AlertDismissable',
    components: {
      Alert
    },
    props: {
      header: String,
      icon: String,
      message: String,
      variant: String
    },
    data() {
      return {
        dismissSecs: 6,
        msg: ''
      }
    },
    mounted(){
      if(this.message && this.message.length > 0){
        this.display(this.message);
      }
    },
    methods: {
      display(msg){
        this.msg = msg;
        this.$refs.alert.setCountDown(this.dismissSecs);
      }
    }
  }
</script>
