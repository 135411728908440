import store from '@/store';

function getAbilities(){
  return store.getters['session/getUserAbilities'];
}

export default {
  can: function(action, subject, params, field){
    params = params ? params : {};
    params['subjectName'] = subject;
    return getAbilities().can(action, params, field);
  },
  getAbilities: getAbilities
}
