<template>
  <Form @submit="save" id='provider-add-form' :saveVisibleOverride='canUpdateProvider()'>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("name")' className='provider' fieldName='name' label='Provider Name' v-model='provider.name' :canUpdate='canUpdateProvider("name")'/>
      <FormSelect v-if='canReadField("type")' className='provider' fieldName='type' v-model='provider.type' :canUpdate='canUpdateProvider("type")' :options='providerTypes'/>
    </b-row>
    <b-row class='mb-5' >
      <FormSelect v-if='canReadField("status")' className='provider' fieldName='status' v-model='provider.status' :canUpdate='canUpdateProvider("status")' :options='statuses'/>
    </b-row>
  </Form>
</template>

<script>
  import rest from '@/common/tools/Rest';
  import abilityTester from '@/config/abilityTester';
  import Form from '@/components/form/Form';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import providerTypesEnum from '@/common/Enums/ProviderType';
  import statusesEnum from '@/common/Enums/Status';

  export default {
    name: 'ProviderAdd',
    components:{
      Form,
      FormInput,
      FormSelect,
    },
    computed: {
    },
    created(){
        this.setDefaults();
    },
    data() {
      return {
        providerTypes: providerTypesEnum,
        statuses: statusesEnum
      }
    },
    props: {
      provider: {}
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canReadField: function(field) {
        return abilityTester.can('read', 'provider', {provider_id: this.provider.id}, field);
      },
      canUpdateProvider: function(field) {
        return this.canUpdate(this.provider.id, field);
      },
      canUpdate(provider_id,field){
        return abilityTester.can('update', 'provider', {provider_id: provider_id}, field);
      },
      save() {
        var data = JSON.parse(JSON.stringify(this.provider));
        var saveProvider = (data) => {
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('provider', 'provider', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.provider, k, val);
            }
            this.$emit('entrypoint_wait_hide');
            this.ProviderChanged();
            this.alertSuccess('Provider Saved');
          }).catch(e => {
              this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
        }
        saveProvider(data);
      },
      ProviderChanged(){
        this.$emit('AddProvider', this.provider);
      },
      setDefaults(){
        if(!this.provider.status){
          this.$set(this.provider,'status', this.statuses.ACTIVE.value);
        }
      },
    },
    mounted(){
    },
    watch: {
      provider: function(){
        this.setDefaults();
      }
    },
  }
</script>
