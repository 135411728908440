<template>
  <b-col :sm='size'>
    <b-row class='incrementNumberRow'>
      <b-col cols='12' align-self='center' class='incrementNumberLabelCol'>
        <FormLabel v-if='useLabel' :labelFor='className + "-" + fieldName' :label='_label' :required='required && canUpdate'/>
      </b-col>
    </b-row>
    <b-row class='incrementNumberRow'>
      <b-col cols='1' align-self='center' class='decrementButtonCol'>
        <IconButton variant='danger' :title='"Decrement " + _label.toUpperCase() + " by 1"' icon='minus' @click='decrement()'/>
      </b-col>
      <b-col cols='9' align-self='center' class='incrementNumberCol'>
        <FormInput :canUpdate='canUpdate' :className='className' :fieldName='fieldName' @input='updateValue($event)' :label='label' :placeholder='placeholder' :required='required' :size='inputSize' :useLabel='false' :value='value'/>
      </b-col>
      <b-col cols='1' align-self='center' class='incrementButtonCol'>
        <IconButton variant='success' :title='"Increment " + _label.toUpperCase() + " by 1"' icon='plus' @click='increment()'/>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import FormInput from '@/components/form/Input';
  import FormLabel from '@/components/form/Label';
  import IconButton from '@/components/elements/IconButton';
  import { mapMutations } from 'vuex';

  export default {
    name: 'FormIncrementNumber',
    components: {
      FormInput,
      FormLabel,
      IconButton
    },
    computed: {
      _label() {
        return this.label ? this.label : this.fieldName.replace(/_/g, ' ').replace(/-/g, ' ');
      }
    },
    data(){
      return {
      }
    },
    props: {
      canUpdate: {
        default: false,
        type: Boolean
      },
      className: String,
      fieldName: String,
      inputSize: {
        default: '12',
        type: String
      },
      label: String,
      min: {
        default: null,
        type: Number
      },
      max: {
        default: null,
        type: Number
      },
      placeholder: String,
      required: {
        default: true,
        type: Boolean
      },
      size: {
        default: '6',
        type: String
      },
      useLabel: {
        default: true,
        type: Boolean
      },
      value: Number
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      decrement(){
        var newVal = this.value - 1;
        this.updateValue(newVal);
      },
      increment(){
        var newVal = this.value + 1;
        this.updateValue(newVal);
      },
      updateValue(newVal){
        newVal = Number(newVal);
        if(!this.validate(newVal)){
          return false;
        }
        this.$emit('input', newVal);
      },
      validate(newVal){
        if(isNaN(newVal)){
          this.error(this._label + ' Must be a number')
          return false;
        }
        if(this.min !== null && this.min !== undefined && newVal < this.min){
          this.error(this._label + ' cannot be less than ' + this.min)
          return false;
        }
        if(this.max !== null && this.max !== undefined && newVal > this.max){
          this.error(this._label + ' cannot be more than ' + this.max)
          return false;
        }
        return true;
      }
    }
  }
</script>

<style scoped>
  .incrementNumberRow {
    padding-left: 0px;
    padding-right: 0px;
  }
  .incrementNumberCol{
    padding: 0px;
  }

  .incrementButtonCol {
    padding: 0px;
  }
</style>