<template>
  <div>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("address1")' :className='_className' fieldName='address1' label='Address 1' v-model='location.address1' :canUpdate='canUpdateField("address1")' @change='LocationChanged' :required='false'/>
      <FormInput v-if='canReadField("address2")' :className='_className' fieldName='address2' label='Address 2' v-model='location.address2' :canUpdate='canUpdateField("address2")' @change='LocationChanged' :required='false'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("city")' :className='_className' fieldName='city' placeholder='City' v-model='location.city' :canUpdate='canUpdateField("city")' @change='LocationChanged' :required='false'/>
      <FormSelect v-if='canReadField("state")' :className='_className' fieldName='state' v-model='location.state' :label='stateType' :canUpdate='canUpdateField("state")' :options='states' @change='LocationChanged' :required='false'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("zip")' :className='_className' fieldName='zip' placeholder='Zip Code' v-model='location.zip' :canUpdate='canUpdateField("zip")' @change='LocationChanged' :required='false'/>
      <FormSelect v-if='canReadField("country")' :className='_className' fieldName='country' v-model='location.country' :canUpdate='canUpdateField("country")' :options='countries' @change='LocationChanged' :required='false'/>
    </b-row>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import countryEnum from '@/common/Enums/Country';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import stateEnum from '@/common/Enums/State';
  import stateTypeEnum from '@/common/Enums/StateType';
  import statusEnum from '@/common/Enums/Status';

  export default {
    name: 'LocationEdit',
    components: {
      FormInput,
      FormSelect
    },
    computed: {
      _className() {
        return this.className ? this.className + '-location' : 'location';
      },
      states() {
        if(this.location.country && stateEnum[this.location.country]){
          return stateEnum[this.location.country];
        }
        return [];
      },
      stateType(){
        if(this.location.country){
          for(var k in countryEnum){
            if(countryEnum[k].value == this.location.country)
            return countryEnum[k].typeDisplay;
          }
        }
        return stateTypeEnum.STATE.display;
      }
    },
    created(){
      this.setDefaults();
    },
    data() {
      return {
        countries: countryEnum,
        statuses: statusEnum
      }
    },
    props: {
      location: {},
      className: String
    },
    methods: {
      canReadField: function(field) {
        return abilityTester.can('read', this._className, {}, field);
      },
      canUpdateField: function(field) {
        return abilityTester.can('update', this._className, {}, field);
      },
      setDefaults(){
          if(!this.location.country){
            this.$set(this.location, 'country', countryEnum.US.value); // This set is needed to make sure the state dropdown is updated correctly
          }
          if(!this.location.status){
            this.$set(this.location,'status', this.statuses.ACTIVE.value);
          }
      },
      LocationChanged(){
        this.$emit('UpdateLocation', this.location);
      }
    },
    watch: {
      location: function(){
        this.setDefaults();
      }
    },
  }
</script>

<style scoped>
</style>
