<template>
  <div class='page-content'>
    <h1>404</h1>
    <div>Unable to find {{ $route.path }}</div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
    data() {
      return {
      }
    }
  }
</script>

<style scoped>
.page-content {
  text-align: center;
  margin-top: 100px;
}
</style>
