export default {
  ACTIVE: {
    display: 'Active',
    value: 'ACTIVE'
  },
  LOCKED: {
    display: 'Locked',
    value: 'LOCKED'
  },
  PENDING_ACTIVATION: {
    display: 'Pending Activation',
    value: 'PENDING'
  },
  SUSPENDED: {
    display: 'Suspended',
    value: 'SUSPENDED'
  }
};
