<template>
  <b-row>
    <b-col>
      <b-table v-if='data.length > 0' striped hover :fields='fields' :items='data'>
        <template v-for="(field, i) in fields" v-slot:[getCellName(field.key)]="data">
          <div v-bind:key='i'>
            <StatusIndicator v-if='field.displayStatus' :status='data.item["status"]'/>
            <slot :name="field.key" v-bind="data">
              <FormInput className='className' fieldName='field.key' v-model='data.item[field.key]' :canUpdate='field.type == "input"' :useLabel='false' :value='data.item[field.key]' class='field'/>
            </slot>
          </div>
        </template>
        <template v-if='dataActions' v-slot:cell(Action)='row'>
          <b-button v-for='(settings, da) in actionSettings[row.index]' v-bind:key='da' size='xs' :variant='getVariant(da, settings)' class='action' v-b-tooltip hover :title='actions[da].title' @click='$emit("clickAction", da, row.item, row.index)'>
            <font-awesome-icon v-if='actions[da].icon' :icon='actions[da].icon'/>
          </b-button>
        </template>
      </b-table>
      <b-alert v-else sm='12' :show='true' variant='warning'>No matching {{className}}(s) found.</b-alert>
    </b-col>
  </b-row>
</template>

<script>
  import StatusIndicator from '@/components/elements/StatusIndicator';
  import FormInput from '@/components/form/Input';

  export default {
    name: 'SearchResults',
    components: {
      StatusIndicator,
      FormInput,
    },
    computed: {
      actionSettings() {
        var settings = [];
        for(var i in this.dataActions){
          var setting = {};
          for(var k in this.dataActions[i]){
            var key = this.dataActions[i][k];
            if(this.actions[key]){
              setting[key] = this.actions[key];
            }
          }
          settings.push(setting);
        }
        return settings;
      }
    },
    data(){
      return {
      }
    },
    props: {
      actions: {},
      className: String,
      data: Array,
      dataActions: Array,
      fields: {},
      slots: Array
    },
    methods: {
      displayTransform(transform, value){
        if(transform){
          if(typeof transform === 'function'){
            return transform(value);
          }
          if(typeof transform === 'object'){

            if(transform.hasOwnProperty(value) && typeof transform[value] !== 'object'){
              return transform[value];
            }
            // Check for enums
            for(var k in transform){
              var v = transform[k];
              if(typeof v === 'object' && v.hasOwnProperty('value') && v.hasOwnProperty('display') && v['value'] == value){
                return v['display'];
              }
            }
          }
        }
        return value;

      },
      getCellName(key){
        return 'cell(' + key + ')';
      },
      getVariant(action, settings){
        if(settings.variant){
          return settings.variant;
        }
        switch(action){
          case 'delete':
            return 'danger';
          case 'view':
            return 'primary';
          case 'unlink':
            return 'warning';
          default:
            return 'success';
        }

      }
    }
  }
</script>

<style scoped>
  .action{
    margin-right: 5px;
  }
  .action:last-child{
    margin-right: 0px;
  }
  .field {
    float: inherit;
    display: inline;
    padding-left: 0px;
  }
  .field >>> .inputRow {
    display: inline-block;
    width: inherit;
  }
</style>
