import ipaddr from 'ipaddr.js';
var utils = {
  date: {
    add(date, numDays){
      date = new Date(date);
      date.setDate(date.getDate() + numDays);
      return date;
    },
    format: {
      mdy(date, separator = '-', pad = true){
        var year = date.getFullYear();
        var month = (date.getMonth() + 1);
        var day = date.getDate();
        if(pad){
          month = month.toString().padStart(2, '0');
          day = day.toString().padStart(2, '0');
        }
        return month + separator + day + separator + year;
      },
      mdyToDateTime(date, separator = '-'){
        return utils.date.format.ymdToDateTime(utils.date.format.mdyToYmd(date, separator));
      },
      mdyToDateUTC(date, separator = '-'){
        return utils.date.format.ymdToDateUTC(utils.date.format.mdyToYmd(date, separator));
      },
      mdyToYmd(date, separator = '-'){
        var parts = date.split(separator);
        if(parts.length != 3){
          throw new Error('Unable to format date: Invalid date ' + date);
        }
        return parts[2] + separator + parts[0] + separator + parts[1];
      },
      ymd(date, separator = '-', pad = true){
        var year = date.getFullYear();
        var month = (date.getMonth() + 1);
        var day = date.getDate();
        if(pad){
          month = month.toString().padStart(2, '0');
          day = day.toString().padStart(2, '0');
        }
        return year + separator + month + separator + day;
      },
      ymdToDateTime(date, separator = '-'){
        var parts = date.split(separator);
        if(parts.length != 3){
          throw new Error('Unable to format date: Invalid date ' + date);
        }
        return new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
      },
      ymdToDateUTC(date, separator = '-'){
        var parts = date.split(separator);
        if(parts.length != 3){
          throw new Error('Unable to format date: Invalid date ' + date);
        }
        return Date.UTC(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
      },
      ymdToMdy(date, separator = '-'){
        var parts = date.split(separator);
        if(parts.length != 3){
          throw new Error('Unable to format date: Invalid date ' + date);
        }
        return parts[1] + separator + parts[2] + separator + parts[0];
      },
    },
    subtract(date, numDays){
      date = new Date(date);
      date.setDate(date.getDate() - numDays);
      return date;
    },
  },
  getObjectValueByPath(object, path, defaultVal = ''){
    if(path === undefined){
      return defaultVal;
    }
    for(let key of path.split('.')){
        object = object[key];
      if(object === undefined){
        return defaultVal;
      }
    }
    if(typeof defaultVal == 'string' && typeof object == 'number' ){
        object += '';
    }
    return typeof defaultVal != typeof object ? defaultVal: object;
  },
  ipv4ToIpv6(ipv4Addr, ipv6Base){
    var ipv6 = ipaddr.parse(ipv4Addr).toIPv4MappedAddress().toString();
    ipv6 = ipv6.split(':').splice(-2,2).join(':');
    ipv6 = ipv6Base + ':' + ipv6;
    ipv6 = ipaddr.parse(ipv6).toString();
    return ipv6;
  },
  setObjectValueByPath(object, path, value){
    if(object === undefined || path === undefined){
      return;
    }
    const parts = path.split('.');
    const limit = parts.length - 1;
    for (let i = 0; i < limit; ++i) {
        const key = parts[i];
        object = object[key] ?? (object[key] = { });
    }
    const key = parts[limit];
    object[key] = value;
  },
  string: {
    keyToDescr(key){
      return utils.string.ucwords(utils.string.ucwords(utils.string.ucwords(key, ' '), '-'), '_');
    },
    ucwords(str, delimiter){
      str = str.split(delimiter);
      for (let j = 0; j < str.length; j++) {
        str[j] = str[j][0].toUpperCase() + str[j].substr(1).toLowerCase();
      }
      return str.join(' ');
    }
  },
  urls: {
    toDomain(url){
      var domain = url.replace(/^https?:\/\//,'').split('.')[0];
      return domain === undefined ? '' : domain;
    }
  }
}

export default utils;
