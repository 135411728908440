import Vue from 'vue'
import Vuex from 'vuex'
import session from '@/store/modules/session';
import messages from '@/store/modules/messages';

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    messages: messages,
    session: session,
  }
})
