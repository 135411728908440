import stateType from '@/common/Enums/StateType';
export default {
  CANADA: {
    display: 'Canada',
    typeDisplay: stateType.PROVINCE_TERRITORY.display,
    value: 'CA'
  },
  US: {
    display: 'United States',
    typeDisplay: stateType.STATE.display,
    value: 'US'
  },
}
