<template>
  <b-button :size='size' :variant='variant' v-b-tooltip hover :title='title' @click='$emit("click")' :class='btnClasses'>
    <font-awesome-icon v-if='iconSource == "fontAwesome"' :size='fontSize' :icon='icon'/>
    <Icon v-else-if='iconSource == "codicon"' :style='{ fontSize: fontSize }' :icon='codiconIcon'/>
  </b-button>
</template>

<script>
  import { Icon } from '@iconify/vue2'
  import collapseAll from '@iconify/icons-codicon/collapse-all'
  import expandAll from '@iconify/icons-codicon/expand-all'

  export default {
    name: 'IconButton',
    components:{
      Icon
    },
    computed: {
      btnClasses(){
        var classes = '';
        if(this.noFocusOutline){
          classes += ' noFocusOutline'
        }
        if(!this.showBorder){
          classes += ' hideBorder'
        }
        return classes;
      },
      codiconIcon() {
        switch(this.icon){
          case 'codicon:collapse-all':
            return collapseAll;
          case 'codicon:expand-all':
            return expandAll;
        }
        return '';
      },
      iconSource() {
        if(this.icon.startsWith('codicon:')){
          return 'codicon';
        }
        return 'fontAwesome';
      }
    },
    data(){
      return {
      }
    },
    props: {
        fontSize: {
          default: null,
          type: String
        },
        icon: {
          required: true,
          type: String
        },
        noFocusOutline: {
          default: false,
          type: Boolean
        },
        size: {
          default: 'xs',
          type: String
        },
        showBorder: {
          default: true,
          type: Boolean
        },
        title: String,
        variant: {
          default: 'success',
          type: String
        }
    },
  }
</script>

<style scoped>
  .noFocusOutline:focus, .noFocusOutline:active {
    box-shadow: none;
    outline: none;
  }

  .hideBorder {
    border: none;
    padding: 0px;
  }
</style>
