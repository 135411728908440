import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss');

//Add neededFont-Awesome icons
import {faCheck, faChevronDown, faChevronUp, faCube, faCubes, faEdit, faFileCsv, faFileInvoiceDollar, faLayerGroup, faMinus, faSearch, faExclamationTriangle, faPlus, faSave, faSyncAlt, faTimes, faUnlink, faUsers, faUser} from '@fortawesome/free-solid-svg-icons'
library.add(faCheck, faChevronDown, faChevronUp, faCube, faCubes, faEdit, faFileCsv, faFileInvoiceDollar, faLayerGroup, faMinus, faSearch, faExclamationTriangle, faPlus, faSave, faSyncAlt, faTimes, faUnlink, faUsers, faUser);

Vue.use(BootstrapVue);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
