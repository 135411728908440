<template>
  <div class='nestedContainer'>
    <h3 class='nestedButtons'>
      <IconButton v-if='collapsed' icon='plus' variant='outline-dark' @click='setCollapsedState(false)' class='expandable' :noFocusOutline='true'/>
      <IconButton v-else icon='minus' variant='outline-dark' @click='setCollapsedState(true)' class='expandable' :noFocusOutline='true'/>
        {{ title }}
    </h3>
    <div v-if='!collapsed' class='nestedForm'>
      <slot v-if='!collapsed'></slot>
      <div class='nestedEnd'/>
    </div>
  </div>
</template>

<script>
  import IconButton from '@/components/elements/IconButton';

  export default {
    name: 'CollapsibleSection',
    components:{
      IconButton
    },
    data(){
      return {
      }
    },
    props: {
        collapsed: {
          default: false,
          type: Boolean
        },
        title: String,
    },
    methods: {
      setCollapsedState(collapsed){
        this.$emit('setCollapsedState', collapsed);
      },
    },
  }
</script>

<style scoped>
  .expandable {
    margin-right: 10px;
  }
  .nestedForm {
    border-left: dotted #ddd;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-top: 5px;
    padding-bottom: 0px;
    padding-left: 15px;
    position: relative;
  }
  .nestedForm > .nestedEnd {
    border-left: solid white;
    border-top: dotted #ddd;
    bottom: 0px;
    height: 15px;
    left: -3px;
    position:absolute;
    width: 15px;
  }
</style>