<template>
  <b-col :sm='size'>
    <FormLabel v-if='useLabel' :labelFor='className + "-" + fieldName' :label='_label' :required='required && canUpdate' :sameLine='singleLine'/>
    <b-row v-if='canUpdate' class='inputRow'>
      <b-col :cols='useRandom ? 11 : 12' :class='useRandom ? "inputColRandom" : "inputCol"'>
        <input ref='input' :type="type" :id='className && fieldName ? className + "-" + fieldName : Date.now()' :name='fieldName' class='form-control ' :value="value" :placeholder="placeholder ? placeholder : label" @input='$emit("input", $event.target.value)' @change='$emit("change", $event.target.value)' :required='required' :autocomplete='autocomplete'/>
      </b-col>
      <b-col cols='1' align-self='center' class='randomButtonCol'>
        <b-button v-if='useRandom' class='randomButton' size='xs' variant='success' v-b-tooltip hover :title='randomTitle' @click='generateRandom'>
          <font-awesome-icon icon='sync-alt'/>
        </b-button>
      </b-col>
    </b-row>
    <span v-else ref='input'>
      {{value}}
    </span>
  </b-col>
</template>

<script>
  import FormLabel from '@/components/form/Label';
  var generator = require('generate-password');

  export default {
    name: 'FormInput',
    components: {
      FormLabel
    },
    computed: {
      _label() {
        return this.label ? this.label : this.fieldName.replace(/_/g, ' ').replace(/-/g, ' ');
      }
    },
    data(){
      return {
      }
    },
    props: {
      autocomplete: {
        default: 'on',
        type: String
      },
      canUpdate: {
        default: false,
        type: Boolean
      },
      className: String,
      fieldName: String,
      label: String,
      required: {
        default: true,
        type: Boolean
      },
      placeholder: String,
      randomExclude: {
        default: '',
        type: String
      },
      randomExcludeSimilar: {
        default: false,
        type: Boolean
      },
      randomLength: {
        default: 18,
        type: Number
      },
      randomTitle: {
        default:'Generate Random Value',
        type: String
      },
      randomUseNumbers: {
        default: true,
        type: Boolean
      },
      randomUseSymbols: {
        default: false,
        type: Boolean
      },
      singleLine: {
        default: false,
        type: Boolean
      },
      size: {
        default: '6',
        type: String
      },
      type: {
        default: 'text',
        type: String
      },
      useLabel: {
        default: true,
        type: Boolean
      },
      useRandom: {
        default: false,
        type: Boolean
      },
      value: [Number, String]
    },
    methods: {
      focus() {
        this.$refs.input.focus();
      },
      generateRandom(){
        var options = {
          exclude: this.randomExclude,
          excludeSimilarCharacters: this.randomExcludeSimilar,
          length: this.randomLength,
          numbers: this.randomUseNumbers,
          strict: true,
          symbols: this.randomUseSymbols,
        }
        var random = generator.generate(options);
        this.$emit('input', random);
      }
    }
  }
</script>

<style scoped>
  .inputRow {
    margin: 0px;
    padding: 0px;
  }
  .inputCol{
    padding: 0px;
  }
  .inputColRandom {
    padding-left: 0px;
  }
  .randomButton {
    float: right;
  }
  .randomButtonCol {
    padding: 0px;
  }
</style>