<template>
  <Form @submit.prevent="handleSubmit" id="product-form" :tableData='products' className='product' :defaultVisibleOverride='showProduct' :tableFields='tableFields' :tableDataActions='tableDataActions' @add='handleAdd' @cancel='hideProduct' @clickAction='handleAction' @deleted='load'>
    <ProductEdit v-if='products[selectedProductIndex]' ref='product' :product='products[selectedProductIndex]' @saved='hideProduct' :manufacturer_id='manufacturer_id' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'></ProductEdit>
  </Form>
</template>

<script>
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import abilityTester from '@/config/abilityTester';
  import ProductEdit from '@/components/product/Edit';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import rest from '@/common/tools/Rest';

  export default {
    name: 'Products',
    components: {
      Form,
      ProductEdit,
    },
    computed: {
      tableDataActions() {
        var resp = [];
        for (var i in this.products) {
          var actions = [];
          actions.push('view');
          if (this.canDeleteProduct(this.products[i].id)) {
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    created() {
      this.load();
    },
    data() {
      return {
        products: [],
        selectedProductIndex: null,
        showProduct: false,
        tableFields: [
          {key: 'acronym', label: 'Product'},
          {key: 'Action', class: 'text-center'}
        ],
      }
    },
    props: {
      manufacturer_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canDeleteProduct(id) {
        return abilityTester.can('delete', 'product', {product_id: id});
      },
      load() {
        var args = {
          'filter': 'Products.status neq INACTIVE',
          'loading': 'Products'
        }
        this.$emit('entrypoint_wait_show', 'Loading Products...');
        rest.get('manufacturer/' + this.manufacturer_id, 'manufacturer', args).then(response => {
          this.products = response.Products ? response.Products : [];
          this.selectedProductIndex = null;
          this.showProduct = false;
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        }).finally(() => {
          this.$emit('entrypoint_wait_hide');
        })
      },
      displayProduct(index) {
        this.selectedProductIndex = index;
        this.showProduct = true;
      },
      handleAction(action, item, index) {
        switch(action) {
            case 'delete':
                this.displayDeleteModal(item.id);
                break;
            case 'view':
                this.displayProduct(index);
                break;
        }
      },
      handleAdd() {
        this.displayProduct(this.products.push({'id': ''}) - 1);
      },
      handleSubmit() {
        this.$refs.product.save();
      },
      hideProduct() {
        this.showProduct = false;
      },
    },
  }
</script>
