import axios from 'axios';
import abilityTester from '@/config/abilityTester';
import store from '@/store';
import {LOGOUT,REFRESH_COOKIE} from '@/store/ActionTypes';

var rest = {
  delete: function(url, className, config = {}){
    url = this.getUrl(url);
    this.prepareConfig(config);
    return axios.delete(url, config).then((resp) => {
      return this.processResponse(resp, className);
    });
  },
  get: function(url, className, args = {}, config = {}){
    url = this.getUrl(url);
    var paramsStarted = url.indexOf('?') >= 0;
    for(var key in args){
      if(paramsStarted){
        url += '&';
      } else {
        url += '?';
        paramsStarted = true;
      }
      url += key + '=' + args[key];
    }
    this.prepareConfig(config);
    return axios.get(url, config).then((resp) => {
      return this.processResponse(resp, className);
    });
  },
  getData: function(response, path, object, allow_empty = false){
    if(response[path] && response[path][object] && response[path][object].error){
      throw new Error(response[path][object].message, response[path][object].error);
    }
    if(response[path][object]){
      return response[path][object];
    }
    if(!allow_empty){
      throw new Error('Invalid object provded.');
    }
    return {};
  },
  getErrorMessage(e){
    if(e.response){
      if(e.response.data.message){
        return e.response.data.message;
      } else {
        return e.response.data;
      }
    } else {
      return e.message;
    }
  },
  getUrl: function(url){
    return '/api/' + process.env.VUE_APP_API_VERSION + '/' + url.replace(/$\//, '');
  },
  logout: function(){
    return this.post('user/logout').then(() => {
    store.dispatch('session/' + LOGOUT);
    location.reload();
    });
  },
  post: function(url, className, data = {}, permissionName = '', preProcessData = true, config = {}){
    url = this.getUrl(url);
    this.prepareConfig(config);
    if(preProcessData){
      data = this.preProcess(data, className, permissionName, 'create', ['id']);
    }
    return axios.post(url, data, config).then((resp) => {
      return this.processResponse(resp, className);
    });
  },
  prepareConfig(config = {}){
    if(!config.headers){
      config.headers = {};
    }
    config.withCredentials = true;
    config.headers["Content-Type"] = 'application/json';
  },
  preProcess(data = {}, className = '', permissionName = '', permissionAction = 'update', removeKeys = []){
    var d = JSON.parse(JSON.stringify(data)); // dont make updates to actual object
    var params = {}
    if(d['id']){
      params[className + '_id'] = d['id'];
    }
    var relationPermissions = {};
    if(typeof permissionName === 'object'){
      relationPermissions = permissionName.relations ? permissionName.relations : {};
      delete permissionName.relations;
      permissionName = permissionName.permissionName ? permissionName.permissionName : '';
    }
    permissionName = permissionName ? permissionName : className;

    for(var key in d){
      if(removeKeys.indexOf(key) >= 0){
        delete d[key];
        continue;
      }
      if(!abilityTester.can(permissionAction, permissionName, params, key)){
        delete d[key];
        continue;
      }
      if(key.charAt(0) == key.charAt(0).toUpperCase() && typeof d[key] === 'object' && d[key]){ // see if it is a relation
        var relationPermission = relationPermissions[key] ? relationPermissions[key] : '';
        d[key] = this.preProcess(d[key], key.toLowerCase(), relationPermission, permissionAction, removeKeys);
        continue;
      }
    }
    return d;
  },
  preProcessPut(data = {}, className = '', permissionName = ''){
    return this.preProcess(data, className, permissionName, 'update', ['created_at', 'id', 'updated_at']);
  },
  processResponse: function(resp, className){
    var blankStatus = [201, 202, 204];
    if(blankStatus.indexOf(resp.status) < 0 && !resp.data){
      throw new Error('Invalid response received.');
    }

    switch(resp.status){
      case 201:
      case 202:
      case 204:
        return '';
      case 400:
      case 401:
      case 404:
      case 409:
      case 501:
        throw new Error(resp.data.message, resp.data.code);
    }
    return this.getData(resp.data, 'data', className, true);
  },
  put: function(url, className, data = {}, permissionName = '', preProcessData = true, config = {}){
    url = this.getUrl(url);
    this.prepareConfig(config);
    if(preProcessData){
      data = this.preProcessPut(data, className, permissionName);
    }
    return axios.put(url, data, config).then((resp) => {
      return this.processResponse(resp, className);
    });
  },
  patch: function(url, className, data = {}, config = {}){
    url = this.getUrl(url);
    this.prepareConfig(config);
    return axios.patch(url, data, config).then((resp) => {
      return this.processResponse(resp, className);
    });
  },
  request: function(config, changeUrl = true, prepareConfig = true){
    if(changeUrl){
      config.url = this.getUrl(config.url);
    }
    if(prepareConfig){
      this.prepareConfig(config);
    }
    var resp = axios.request(config);
    return resp;
  },
  save: function(url, className, data = {}, permissionName = '', preProcessData = true, config = {}){
    var query = [];
    var variables = ['filter', 'loading', 'order'];
    for(var i in variables){
      var variable = variables[i];
      if(data[variable]){
        query.push(variable + '=' + data[variable]);
        delete data[variable]
      }
    }
    var queryString = query.length > 0 ? '?' + query.join('&') : '';

    if(data.id){
      return this.put(url + '/' + data.id + queryString, className, data, permissionName, preProcessData, config);
    } else {
      return this.post(url + queryString, className, data, permissionName, preProcessData, config);
    }
  }
}

axios.interceptors.response.use(function(response){
    store.dispatch('session/' + REFRESH_COOKIE);
    return response;
}, function(err){
    if(err.response.status === 401 && err.config && !err.config.__isRetryRequest){
      rest.logout();
    } else {
      store.dispatch('session/' + REFRESH_COOKIE);
      return Promise.reject(err);
    }
})
export default rest;
