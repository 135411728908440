<template>
<div>
  <SearchHeader title='Users' icon='users' prompt='Search for a user by providing a user name, email, or contact name.' @change='search'/>
  <SearchResults v-if='searchText || users.length > 0' :data='users && Array.isArray(users) ? users : []' className='user' :fields='tableFields' :actions='tableActions ? tableActions : []' :dataActions='tableDataActions ? tableDataActions : []' @clickAction='handleAction'>
    <template v-slot:Contact='row'>
      <span v-if='row.item.Contact'>
        {{row.item.Contact.first_name}} {{row.item.Contact.last_name}}
        <span v-if='row.item.Contact.email'> - {{row.item.Contact.email}}</span>
      </span>
      <span v-else>None</span>
    </template>
  </SearchResults>
  <b-row v-if='canCreateUser()'>
    <b-col>
      <b-button size='xs' variant='primary' @click='addUser()'>Add User</b-button>
    </b-col>
  </b-row>
  <DeleteRecordModal ref='deleteModal' className='user' :id='deleteId' :queryString='deleteQuery' @deleted='search(searchText)'/>
  <Modal id='user_modal' v-model='showUserModal' title='Edit User' title-tag='h4' button-size='xs' cancel-variant='default' size='lg' hide-footer>
    <UserEdit ref='user' @UpdateUser='UpdateUser' profile="true" :user="users[selectedUserIndex] ? users[selectedUserIndex] : {}" @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'></UserEdit>
  </Modal>
</div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import DeleteRecordModal from '@/components/modal/DeleteRecord'
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import Modal from '@/components/modal/Modal';
  import rest from '@/common/tools/Rest';
  import SearchHeader from '@/components/search/Header';
  import SearchResults from '@/components/search/Results';
  import UserEdit from '@/components/user/Edit';

  export default {
    name: 'UserList',
    components: {
      DeleteRecordModal,
      Modal,
      SearchHeader,
      SearchResults,
      UserEdit
    },
    computed: {
      tableDataActions() {
        var resp = [];
        for(var i in this.users){
          this.users[i];
          var actions = [];
          actions.push('view');
          if(this.canDeleteUser()){
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    data() {
      return {
        deleteId: '',
        deleteQuery: '',
        searchText: '',
        selectedUserIndex: null,
        showUserModal: false,
        tableActions: {
          delete: {title: 'Delete', icon: 'times'},
          view: {title: 'View', icon: 'edit'}
        },
        tableFields: [
          {key: 'username', label: 'User', displayStatus: true},
          {key: 'Contact', class: 'text-center'},
          {key: 'Action', class: 'text-center'}
        ],
        users: []
      }
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      addUser(){
        var newUser = {};
        var i = this.users.push(newUser) - 1;
        this.displayUserModal(i);
      },
      canCreateUser(){
        return abilityTester.can('create', 'user');
      },
      canDeleteUser(){
        return abilityTester.can('delete', 'user');
      },
      displayDeleteModal(id){
        this.deleteId = id;
        this.$refs.deleteModal.show();
      },
      displayUserModal(index){
        this.selectedUserIndex = index;
        this.showUserModal = true;
      },
      handleAction(action, item, index){
        switch(action){
          case 'delete':
            this.displayDeleteModal(item.id);
            break;
          case 'view':
            this.displayUserModal(index);
            break;
        }

      },
      search(text){
        if(text.length === 0){
          this.searchText = '';
          this.users = [];
          return;
        }
        this.selectedUserIndex = null;
        this.searchText = text;
        var args = {
          'loading': 'Contact'
        }
        this.$emit('entrypoint_wait_show','Searching...');
        rest.get('user/search/' + text, 'user', args).then(users => {
          this.users = Array.isArray(users) ? users : [];
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      UpdateUser(user) {
        this.users[this.selectedUserIndex] = user;
        this.$refs.user.$forceUpdate();
      },
    }
  }
</script>
