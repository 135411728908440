<template>
  <div>
    <h3>
      <font-awesome-icon icon="user" />
      User Profile
    </h3>
    <UserEdit ref='user' @UpdateUser='UpdateUser' profile="true" :user="user" @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'></UserEdit>
  </div>
</template>

<script>
  import UserEdit from '@/components/user/Edit';
  import { mapMutations } from 'vuex';
  import store from '@/store';
  import {SET_USER} from '@/store/MutationTypes';


  export default {
    name: 'UserProfile',
    components: {
      UserEdit
    },
    computed: {
      user() {
        return store.state.session.user ? store.state.session.user : {};
      }
    },
    methods: {
      ...mapMutations('session', {
        setUser: SET_USER
      }),
      UpdateUser(user) {
        this.setUser(user);
        this.$refs.user.$forceUpdate();
      },
    }
  }
</script>

<style scoped>
</style>
