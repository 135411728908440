<template>
  <b-alert :show="dismissCountDown" class="SmallBox" :dismissible="dismissible" :variant="variant" @dismiss-count-down="setCountDown">
    <div class="foto" v-if="icon">
      <font-awesome-icon :icon="icon" />
    </div>
    <div class="textoFoto">
      <span>{{ header }}</span>
      <p>{{ message }}</p>
    </div>
  </b-alert>
</template>

<script>
  export default {
    name: 'Alert',
    data(){
      return {
        dismissCountDown: 0
      }
    },
    props: {
        dismissible: {
          default: false,
          type: Boolean
        },
        header: String,
        icon: String,
        message: String,
        variant: String
    },
    methods: {
      dismissed(){
        this.dismissCountDown = 0;
        this.$emit("dismissed");
      },
      setCountDown(dismissCountDown){
        this.dismissCountDown = dismissCountDown;
        if(dismissCountDown == 0){
          this.dismissed();
        }
      }
    }
  }
</script>

<style scoped>
    .SmallBox {
    position: relative;
    right: 5px;
    top: 20px;
    width: 420px;
    color: #fff;
    z-index: 9999;
    overflow: hidden;
    border: 1px solid transparent;
    padding: 0px;
  }
  .SmallBox.alert-danger {
    background-color: rgb(196, 106, 105);
  }
  .SmallBox.alert-success {
    background-color: rgb(115, 158, 115);
  }
  .SmallBox span {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -1px;
    display: block;
    margin: 4px 0;
}
.SmallBox p {
    font-size: 13px;
    margin-top: 2px;
}
.SmallBox .textoFoto {
    width: 78%;
    margin: 3px 20px 3px 80px;
    float: left;
    text-align: left;
    white-space: pre-wrap;
}
.SmallBox .foto {
    font-size: 50px;
    position: absolute;
    left: 17px;
}
</style>
