<template>
  <div>
    <SearchHeader title='Providers' icon='cubes' prompt='Search for a provider by providing a name, description, or other identifying details.' @change='search'/>
    <SearchResults v-if='searchText || providers.length > 0' :data='providers && Array.isArray(providers) ? providers : []' className='provider' :fields='tableFields' :actions='tableActions ? tableActions : []' :dataActions='tableDataActions  ? tableDataActions : []' @clickAction='handleAction'/>
    <b-row v-if='canCreateProvider()'>
      <b-col>
        <b-button size='xs' variant='primary' @click='clickAddProvider()'>Add Provider</b-button>
      </b-col>
    </b-row>
    <DeleteRecordModal ref='deleteModal' className='provider' :id='deleteId' @deleted='search(searchText)'/>
    <TabbedModal v-if='providers[selectedProviderIndex]' id='provider_modal' :visible='showProviderModal' @change='handleHideTabbedModal' title='Manage Provider' @input='setSelectedTab'>
      <Tab title='Details'>
        <Form @submit="handleSubmit" id='provider-form' :saveVisibleOverride='canUpdateProvider()'>
          <ProviderEdit ref='provider' @UpdateProvider='UpdateProvider' :provider="providers[selectedProviderIndex]" @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
        </Form>
      </Tab>
      <Tab v-if='canReadApi()' title='API' :disabled='selectedProviderIsNew'>
        <ApiList ref='api' :provider_id="selectedProviderId" v-if='!selectedProviderIsNew && selectedTab == 1' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
      </Tab>
      <Tab v-if='canReadSystemstats()' title='System Stats' :disabled='selectedProviderIsNew'>
        <Systemstats ref='systemstats' :provider_id="selectedProviderId" v-if='!selectedProviderIsNew && selectedTab == 2' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
      </Tab>
    </TabbedModal>
    <Modal v-else-if="showProviderAddModal" id='provider_add_modal' button-size='xs' :ok-only='true' ok-title='Close' ok-variant='default' footer-class='modalFooter leftBtns' :visible='showProviderAddModal' @change='handleHideModal' title='Manage Provider'>
      <ProviderAdd :provider="newProvider" @AddProvider='AddProvider' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
    </Modal>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import ApiList from '@/components/provider/Apis';
  import DeleteRecordModal from '@/components/modal/DeleteRecord';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import Modal from '@/components/modal/Modal';
  import TabbedModal from '@/components/modal/TabbedModal';
  import rest from '@/common/tools/Rest';
  import ProviderAdd from '@/components/provider/Add';
  import SearchHeader from '@/components/search/Header';
  import SearchResults from '@/components/search/Results';
  import Systemstats from '@/components/provider/Systemstats';
  import ProviderEdit from '@/components/provider/Edit';
  import Tab from '@/components/elements/tabs/Tab';

  export default {
    name: 'ProviderList',
    components: {
      ApiList,
      DeleteRecordModal,
      Form,
      Modal,
      ProviderAdd,
      ProviderEdit,
      SearchHeader,
      SearchResults,
      Systemstats,
      Tab,
      TabbedModal,
    },
    computed: {
      selectedProviderId(){
        return this.providers[this.selectedProviderIndex] && this.providers[this.selectedProviderIndex].id ? this.providers[this.selectedProviderIndex].id : '';
      },
      selectedProviderIsNew() {
        return  this.selectedProviderId ? false : true
      },
      showProviderAddModal() {
        return this.newProvider == null ? false : true;
      },
      showProviderModal() {
        return this.providers[this.selectedProviderIndex] ? true : false;
      },
      tableDataActions() {
        var resp = [];
        for(var i in this.providers){
          var actions = [];
          actions.push('view');
          if(this.canDeleteProvider(this.providers[i].id)){
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    data() {
      return {
        deleteId: '',
        newProvider: null,
        searchText: '',
        selectedProviderIndex: null,
        selectedTab: null,
        tableActions: {
          delete: {title: 'Delete', icon: 'times'},
          view: {title: 'View', icon: 'edit'}
        },
        tableFields: [
          {key: 'name', displayStatus: true},
          {key: 'Action', class: 'text-center'}
        ],
        providers: []
      }
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      AddProvider(provider){
        this.newProvider = null;
        var i = this.providers.push(provider) - 1;
        this.displayProviderModal(i);
        this.$emit('entrypoint_wait_show', 'Click save to add default configurations to new provider');
        var _this = this;
        setTimeout(function() {_this.$emit('entrypoint_wait_hide');}, 12000);
      },
      canCreateProvider(){
        return abilityTester.can('create', 'provider');
      },
      canDeleteProvider(id){
        return abilityTester.can('delete', 'provider', {provider_id: id});
      },
      canReadApi(id){
        return abilityTester.can('read', 'api', {provider_id: id});
      },
      canReadSystemstats(id){
        return abilityTester.can('read', 'systemstats', {provider_id: id});
      },
      canUpdateProvider(){
        return this.$refs.provider ? this.$refs.provider.canUpdateProvider() : ProviderEdit.methods.canUpdate(this.providers[this.selectedProviderIndex]);
      },
      clickAddProvider(){
        this.handleHideTabbedModal();
        this.newProvider = {};
      },
      displayDeleteModal(id){
        this.deleteId = id;
        this.$refs.deleteModal.show();
      },
      displayProviderModal(index){
        this.selectedProviderIndex = index;
      },
      handleAction(action, item, index){
        switch(action){
          case 'delete':
            this.displayDeleteModal(item.id);
            break;
          case 'view':
            this.displayProviderModal(index);
            break;
        }

      },
      handleHideModal(show){
        if(!show){
          this.newProvider = null;
        }
      },
      handleHideTabbedModal(show){
        if(!show){
          this.selectedProviderIndex = null;
          this.setSelectedTab(0);
        }
      },
      handleSubmit(){
        this.$refs.provider.save();
      },
      search(text){
        if(text.length === 0){
          this.searchText = '';
          this.providers = [];
          return;
        }
        this.selectedProviderIndex = null;
        this.searchText = text;
        var args = {
          'loading': 'Location'
        }
        this.$emit('entrypoint_wait_show', 'Searching...');
        rest.get('provider/search/' + text, 'provider', args).then(providers => {
          this.providers = Array.isArray(providers) ? providers : [];
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      setSelectedTab(value){
        this.selectedTab = value;
      },
      UpdateProvider(provider) {
        this.providers[this.selectedProviderIndex] = provider;
        if(this.$refs.provider){
          this.$refs.provider.$forceUpdate();
        }
      },
    }
  }
</script>

<style scoped>
</style>

<style>
  .modalFooter {
    border-top: none !important;
  }

  .modalFooter.leftBtns {
    display: block;
  }

  .modalFooter.leftBtns > .btn {
    float: left;
  }
</style>
