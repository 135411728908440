<template>
  <div>
    <form>
        <div>
          <b-row class='mb-5' >
            <FormInput className='api' fieldName='created_at' :value='api.created_at' :canUpdate='false' :singleLine='true'/>
            <FormInput className='api' fieldName='updated_at' :value='api.updated_at' :canUpdate='false' :singleLine='true'/>
          </b-row>
          <b-row class='mb-5' >
            <FormInput className='api' fieldName='id' :value='api.id' :canUpdate='false'/>
            <FormInput className='api' fieldName='version' :value='api.version' :canUpdate='false'/>
          </b-row>
          <b-row class='mb-5' >
            <FormInput className='api' fieldName='token' :value='api.token' :canUpdate='false'/>
            <FormInput className='api' fieldName='token_created_at' :value='api.token_created_at' :canUpdate='false'/>
          </b-row>
          <b-row class='mb-5' >
            <FormInput v-if='canReadField("key")' className='api' fieldName='key' v-model='key' :canUpdate='canUpdateApi("key")' :useRandom='true' :required='_isNew'/>
            <FormInput v-if='canReadField("secret")' className='api' fieldName='secret' v-model='secret' :canUpdate='canUpdateApi("secret")' :useRandom='true' randomTitle='Generate Random API Secret' :required='_isNew'/>
          </b-row>
          <b-row class='mb-5' >
            <FormSelect v-if='canReadField("access_type")' className='api' fieldName='access_type' v-model='api.access_type' :canUpdate='canUpdateApi("access_type")' :options='apiAccessTypes'/>
            <FormSelect v-if='canReadField("status")' className='api' fieldName='status' v-model='api.status' :canUpdate='canUpdateApi("status")' :options='statuses'/>
          </b-row>
          <b-row class='mb-5' >
            <FormInput v-if='canReadField("uri")' className='api' fieldName='uri' v-model='api.uri' :canUpdate='canUpdateApi("uri")'/>
          </b-row>
        </div>
    </form>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import apiAccessTypesEnum from '@/common/Enums/Api/AccessType';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import { mapMutations } from 'vuex';
  import rest from '@/common/tools/Rest';
  import statusesEnum from '@/common/Enums/Status';

  export default {
    name: 'ApiEdit',
    components:{
      FormInput,
      FormSelect,
    },
    computed: {
      _isNew(){
        return !(this.api && this.api.id);
      }
    },
    created(){
        this.setDefaults();
    },
    data() {
      return {
        apiAccessTypes: apiAccessTypesEnum,
        key: '',
        secret: '',
        statuses: statusesEnum
      }
    },
    props: {
      api: {},
      provider_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canReadField: function(field) {
        return abilityTester.can('read', 'api', {api_id: this.api.id}, field);
      },
      canUpdateApi: function(field) {
        return abilityTester.can('update', 'api', {api_id: this.api.id}, field);
      },
      save() {
        var data = JSON.parse(JSON.stringify(this.api));
        delete data['key'];
        delete data['secret'];
        if(this.key){
          data['key'] = this.key;
        }
        if(this.secret){
          data['secret'] = this.secret;
        }
        var saveApi = (data) => {
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('provider/' + this.provider_id + '/api', 'api', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.api, k, val);
            }
            this.$emit('entrypoint_wait_hide');
            this.alertSuccess('Api Saved');
            this.$emit('saved');
          }).catch(e => {
              this.$emit('entrypoint_wait_hide');
              this.error(rest.getErrorMessage(e));
          })
        }
        saveApi(data);
      },
      setDefaults(){
        if(!this.api.status){
          this.$set(this.api,'status', this.statuses.ACTIVE.value);
        }
        if(!this.api.version){
          this.$set(this.api, 'version', 'v1');
        }
      },
    },
    watch: {
      api: function(){
        this.setDefaults();
      }
    },
  }
</script>
