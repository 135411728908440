export default {
  CENTRAL_OFFICE: {
    display: 'Central Office',
    value: 'CO'
  },
  CITY: {
    display: 'City',
    value: 'CITY'
  },
  COMMERCIAL: {
    display: 'Commercial',
    value: 'COMMERCIAL'
  },
  COUNTY: {
    display: 'County',
    value: 'COUNTY'
  },
  RESIDENCE: {
    display: 'Residence',
    value: 'RESIDENCE'
  },
}
