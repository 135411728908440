<template>
  <div>
    <b-row class='mb-5' >
      <!--<FormInput v-if='canReadField("first_name")' :className='_className' fieldName='first_name' label='First' v-model='contact.first_name' :canUpdate='canUpdateField("first_name")' @change='ContactChanged'/>-->
      <FormInput :className='_className' fieldName='first_name' label='First' v-model='contact.first_name' :canUpdate='true' @change='ContactChanged'/>
      <FormInput v-if='canReadField("last_name")' :className='_className' fieldName='last_name' label='Last' v-model='contact.last_name' :canUpdate='canUpdateField("last_name")' @change='ContactChanged'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("email")' :className='_className' fieldName='email' v-model='contact.email' :canUpdate='canUpdateField("email")' type='email' @change='ContactChanged'/>
      <FormInput v-if='canReadField("phone")' :className='_className' fieldName='phone' v-model='contact.phone' :canUpdate='canUpdateField("phone")' @change='ContactChanged'/>
    </b-row>
    <b-row class='mb-5' >
      <FormSelect v-if='canReadField("status")' :className='_className' fieldName='status' v-model='contact.status' :canUpdate='canUpdateField("status")' :options='statuses' @change='ContactChanged'/>
    </b-row>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import { mapMutations } from 'vuex';
  import statusEnum from '@/common/Enums/Status';

  export default {
    name: 'ContactEdit',
    components: {
      FormInput,
      FormSelect
    },
    props: {
      contact: {},
      className: String,
      /*manufacturer_id: {
        type: String,
        required: false,
        validator: function(value) {
          return value ? true : false;
        }
      }*/
    },
    computed: {
        _className() {
          return this.className ? this.className + '-contact' : 'contact';
        },
    },
    created(){
      this.setDefaults();
    },
    data() {
      return {
        statuses: statusEnum
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canReadField: function(field) {
        return abilityTester.can('read', this._className, {}, field);
      },
      canUpdateField: function(field) {
        return abilityTester.can('update', this._className, {contact_id: this.contact.id}, field);
      },
      /*save() {
        var data = JSON.parse(JSON.stringify(this.contact));
        var saveContact = (data) => {
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('manufacturer/' + this.manufacturer_id + '/contact', 'contact', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.contact, k, val);
            }
            this.$emit('entrypoint_wait_hide');
            this.alertSuccess('Contact Saved');
            this.$emit('saved');
          }).catch(e => {
              this.$emit('entrypoint_wait_hide');
              this.error(rest.getErrorMessage(e));
          })
        }
        saveContact(data);
      },*/
      ContactChanged(){
        this.$emit('UpdateContact', this.contact);
      },
      setDefaults(){
          if(!this.contact.status){
            this.$set(this.contact,'status', this.statuses.ACTIVE.value);
          }
      },
    },
    watch: {
      contact: function(){
        this.setDefaults();
      }
    },
  }
</script>

<style scoped>
</style>
