import { LOGOUT, REFRESH_COOKIE } from '@/store/ActionTypes';
import {SET_USER} from '@/store/MutationTypes';
import defineAbilitiesFor from '@/config/ability'
var Cookies = require('js-cookie');

export default {
  namespaced: true,
  state: {
    user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null,
  },
  mutations: {
    [SET_USER] (state, user) {
      if(user){
        var expireTime = 1/24; // 1 hour
        Cookies.set('user', JSON.stringify(user), {expires: expireTime});
      } else {
        Cookies.remove('user');
      }
      user = user ? user : {};
      state.user = user;
    },
  },
  actions: {
    [LOGOUT] (context) {
      context.commit(SET_USER, null);
    },
    [REFRESH_COOKIE] (context){
      if(context.state){
        context.commit(SET_USER, context.state.user);
      }
    }
  },
  getters: {
    getUserAbilities: (state) => {
      return defineAbilitiesFor(state);
    },
    isAuthenticated: (state) => {
      return state.user ? true : false;
    }
  }
}
