<template>
  <div>
    <b-row class='mb-3'>
      <b-col sm='8'>
        <h3>
          <font-awesome-icon v-if='icon && icon.length > 0' :icon="icon" />
          {{title}}
        </h3>
      </b-col>
      <b-col sm='4'>
        <div class='header-search input-group pull-right'>
          <input ref='search' type='text' :placeholder='title + " Search"' class='pull-right form-control' @keyup='changed'/>
          <span class='input-group-addon' data-toggle v-b-tooltip.hover title='Search'>
            <font-awesome-icon icon='search'/>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class='mb-2' v-if='prompt && prompt.length > 0'>
      <b-col sm='12'>
        <b-alert :show='true' variant='blank'>
          {{prompt}}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'SearchHeader',
    data(){
      return {
      }
    },
    props: {
      emitChangeOnAnyInput: {
        default: false,
        type: Boolean
      },
      icon: String,
      prompt: String,
      title: String,
    },
    mounted(){
      this.$refs.search.focus();
    },
    methods: {
      changed(e){
        if(this.emitChangeOnAnyInput || e.target.value == '*' || e.target.value.length > 2){
          this.$emit('change', e.target.value);
        }
      }
    }
  }
</script>

<style scoped>
</style>
