<template>
    <Modal :visible='visible' @change='$emit("change", $event)' :id='id' :title='title' :title-tag='titleTag' :button-size='buttonSize' :cancel-title='cancelTitle' :cancel-variant='cancelVariant' :size='size' :hide-footer='hideFooter' :ok-only='_okOnly' :ok-title='_okTitle' :ok-variant='_okVariant' :footer-class='_footerClasses'>
      <Tabs @input='input'>
        <slot/>
      </Tabs>
    </Modal>
</template>
<script>
  import Modal from '@/components/modal/Modal';
  import Tabs from '@/components/elements/tabs/Tabs';

  export default {
    name: 'TabbedModal',
    components: {
      Modal,
      Tabs
    },
    computed: {
      _footerClasses() {
        var classes = this.footerClass;
        classes += ' tabbedModalFooter';
        if(this.cancelOnly){
          classes += ' leftBtns';
        }
        return classes;
      },
      _okOnly(){
        return this.okOnly || this.cancelOnly;
      },
      _okTitle(){
        return this.cancelOnly ? this.cancelTitle : this.okTitle;
      },
      _okVariant(){
        return this.cancelOnly ? this.cancelVariant : this.okVariant;
      }
    },
    props: {
      buttonSize: {
        default: 'xs',
        type: String
      },
      cancelOnly: {
        default: true,
        type: Boolean
      },
      cancelTitle: {
        default: 'Close',
        type: String
      },
      cancelVariant: {
        default: 'default',
        type: String
      },
      footerClass: {
        default: '',
        type: String
      },
      hideFooter: {
        default: false,
        type: Boolean
      },
      id: String,
      okOnly: {
        default: false,
        type: Boolean
      },
      okTitle: {
        default: 'OK',
        type: String
      },
      okVariant: {
        default: 'primary',
        type: String
      },
      size: {
        default: 'lg',
        type: String
      },
      title: String,
      titleTag: {
        default: 'h4',
        type: String
      },
      visible: {
        default: false,
        type: Boolean
      },
    },
    model: {
      prop: 'visible',
      event: 'change'
    },
    methods: {
      input(value){
        this.$emit('input', value);
      }
    }
  }
</script>

<style>
  .tabbedModalFooter {
    border-top: none !important;
  }

  .tabbedModalFooter.leftBtns {
    display: block;
  }

  .tabbedModalFooter.leftBtns > .btn {
    float: left;
  }
</style>
