export default {
  "crypto":{
     "country_code":"US",
     "state_code":""
  },
  "docker":{
    "allow_ipv6_connections": true,
    "docker_swarm_join_token": "",
    "docker_swarm_manager_ip": "ref:networks/management_network/reserved/flowops/ip_address",
    "docker_swarm_manager_port": "2377",
    "docker_swarm_worker_ips": {
      "compliance": "ref:networks/management_network/reserved/compliance_portal/ip_address",
      "subscriber": "ref:networks/management_network/reserved/subscriber_portal/ip_address"
    },
    "extra_hosts": {
       "ipsec-vpn-server": [{ "host": "", "ip": ""}],
       "provider_compliance_dns_0": [{ "host": "", "ip": ""}],
       "provider_compliance_portal": [{ "host": "", "ip": ""}],
       "provider_flowops_api": [{ "host": "", "ip": ""}],
       "provider_flowops_db": [{ "host": "", "ip": ""}],
       "provider_management_dns_0": [{ "host": "", "ip": ""}],
       "provider_management_dns_1": [{ "host": "", "ip": ""}],
       "provider_management_ntp_0": [{ "host": "", "ip": ""}],
       "provider_management_ntp_1": [{ "host": "", "ip": ""}],
       "provider_management_portal": [{ "host": "", "ip": ""}],
       "provider_management_proxy": [{ "host": "", "ip": ""}],
       "provider_management_services_proxy": [{ "host": "", "ip": ""}],
       "provider_subscriber_dns_0": [{ "host": "", "ip": ""}],
       "provider_subscriber_portal": [{ "host": "", "ip": ""}],
       "provider_walled_garden_dns_0": [{ "host": "", "ip": ""}],
       "provider_webhook_api": [{ "host": "", "ip": ""}],
       "provider_websocket_server": [{ "host": "", "ip": ""}],
       "zabbix_agent": [{ "host": "", "ip": ""}],
       "zabbix_database": [{ "host": "", "ip": ""}],
       "zabbix_server": [{ "host": "", "ip": ""}],
       "zabbix_web": [{ "host": "", "ip": ""}]
    },
    "image_tag_overrides": {
       "ipsec-vpn-server": "",
       "provider_compliance_dns_0": "",
       "provider_compliance_portal": "",
       "provider_flowops_api": "",
       "provider_flowops_db": "",
       "provider_management_dns_0": "",
       "provider_management_dns_1": "",
       "provider_management_ntp_0": "",
       "provider_management_ntp_1": "",
       "provider_management_portal": "",
       "provider_management_proxy": "",
       "provider_management_services_proxy": "",
       "provider_subscriber_dns_0": "",
       "provider_subscriber_portal": "",
       "provider_walled_garden_dns_0": "",
       "provider_webhook_api": "",
       "provider_websocket_server": "",
       "zabbix_agent": "",
       "zabbix_database": "",
       "zabbix_server": "",
       "zabbix_web": ""
    },
    "use_lets_encrypt": true,
    "volume_paths": {
      "ssl": "/shared/ssl"
    }
  },
  "local": {
    "constants": {
      "api_key": "",
      "api_secret": "",
      "domain": "",
      "environment": "PRODUCTION",
      "salt": "",
      "site_name": "FlowOps"
    },
    "email": {
      "support": ""
    },
    "entrypoint_communicator": {
      "base_port": "8881",
      "country_code": "US",
      "state_code": ""
    },
    "general": {
      "databases": {
        "default": {
          "database": "flowops",
          "host": "provider_flowops_db",
          "password": "",
          "port": 3306,
          "username": ""
        },
      },
    },
    "urls": {
      "flowops_url": "",
      "internal_flowops_url": "http://provider_flowops_api",
      "internal_management_url": "http://provider_management_portal",
      "internal_websocket_url": "ws://provider_websocket_server:8888",
      "management_url": "",
      "northstar_url": "https://northstar.entpnt.com",
      "subscriber_url":"",
      "webhook_url": ""
    }
  },
  "networks": {
    "ipv6_suffix": "",
    "compliance_network":{
       "details":{
          "allow_ranges":[
             "172.26.24.0/24"
          ],
          "cidr":"172.26.24.0/24",
          "gateway":"172.26.24.1",
          "ipv6_gateway":"",
          "name":"Compliance Network"
       },
       "reserved":{
          "portal":{
             "ip_address":"172.26.24.1",
             "ipv6_address":"",
             "name":"Portal"
          }
       }
    },
    "management_network": {
      "details": {
        "allow_ranges": ["172.26.0.0/24"],
        "name": "Management Network",
        "cidr": "172.26.0.0/24",
        "gateway": "172.26.0.1",
        "ipv6_gateway":"",
      },
      "reserved": {
        "ansible":{
           "ip_address":"172.26.0.11",
           "ipv6_address":"",
           "name":"Ansible"
        },
        "compliance_portal": {
            "ip_address": "172.26.0.30",
            "ipv6_address":"",
            "name": "Compliance Portal",
        },
        "database": {
          "ip_address": "127.0.0.1",
          "name": "Database",
        },
        "firewall": {
            "ip_address": "172.26.0.1",
            "ipv6_address":"",
            "name": "Firewall",
        },
        "flowops": {
          "name": "FlowOps",
          "ip_address": "172.26.0.2",
          "ipv6_address":"",
        },
        "log": {
            "ip_address": "172.26.0.7",
            "ipv6_address":"",
            "name": "Log Server",
        },
        "management": {
          "name": "Management",
          "ip_address": "ref:networks/management_network/reserved/flowops/ip_address",
        },
        "management_services_1": {
            "ip_address": "172.26.0.3",
            "ipv6_address":"",
            "name": "Management Services 1",
        },
        "management_services_2": {
            "ip_address": "172.26.0.4",
            "ipv6_address":"",
            "name": "Management Services 2",
        },
        "openvas":{
           "ip_address":"172.26.0.6",
           "ipv6_address":"",
           "name":"OpenVAS"
        },
        "proxmox_backup":{
           "ip_address":"172.26.0.106",
           "ipv6_address":"",
           "name":"Proxmox Backup Server"
        },
        "proxmox_monitor":{
           "ip_address":"172.26.0.12",
           "ipv6_address":"",
           "name":"Proxmox Monitor Server"
        },
        "subscriber_portal": {
            "ip_address": "172.26.0.20",
            "ipv6_address":"",
            "name": "Subscriber Portal",
        },
        "vnc":{
           "ip_address":"172.26.0.10",
           "ipv6_address":"",
           "name":"VNC Server"
        },
        "vrouter":{
           "ip_address":"172.26.0.254",
           "ipv6_address":"",
           "name":"Vyos Virtual Router"
        },
        "zabbix": {
            "ip_address": "172.26.0.5",
            "ipv6_address":"",
            "name": "Zabbix Monitoring",
        },
      }
    },
    "proxmox_network":{
       "details":{
          "cidr":"",
          "gateway":"",
          "ipv6_gateway":"",
          "name":"Proxmox Network"
       },
       "reserved":{
          "node1":{
             "ip_address":"",
             "ipv6_address":"",
             "name":"Node 1"
          },
          "node2":{
             "ip_address":"",
             "ipv6_address":"",
             "name":"Node 2"
          },
          "node3":{
             "ip_address":"",
             "ipv6_address":"",
             "name":"Node 3"
          }
       }
    },
    "public_network":{
       "details":{
          "cidr":"",
          "gateway":"",
          "ipv6_gateway":"",
          "name":"Public Network"
       },
       "reserved":{
          "firewall":{
             "ip_address":"",
             "ipv6_address":"",
             "name":"Firewall"
          },
          "proxmox_host": {
            "ip_address": "",

            "ipv6_address": "",

            "name": ""
        }
       }
    },
    "walled_garden_network":{
       "details":{
          "allow_ranges":[
             "172.26.14.0/24"
          ],
          "cidr":"172.26.14.0/24",
          "gateway":"172.26.14.1",
            "ipv6_gateway":"",
          "name":"Subscriber Network"
       },
       "reserved":{
          "portal":{
             "ip_address":"172.26.14.1",
             "ipv6_address":"",
             "name":"Portal"
          }
       }
    }
  },
  "sentry": {
     "environment": ""
  },
  "services": {
    "compliance_dns": {
      "internal_urls": ["http://provider_compliance_dns_0"],
      "dhcp": {
        "lease_time": "12h",
        "range_end_ip": "172.26.24.254",
        "range_start_ip": "172.26.24.4",
      }
    },
    "management_dns": {
      "api_key": "",
      "api_secret": "",
      "instances": {
        "provider_management_dns_0": {
          "external_url": ""
        },
        "provider_management_dns_1": {
          "external_url": ""
        }
      },
    },
    "management_ntp": {
      "api_key": "",
      "api_secret": "",
      "instances": {
        "provider_management_ntp_0": {
          "external_url": ""
        },
        "provider_management_ntp_1": {
          "external_url": ""
        }
      },
    },
    "subscriber_dns": {
      "dhcp": {
        "lease_time": "12h",
        "range_end_ip": "172.26.14.254",
        "range_start_ip": "172.26.14.4",
      },
      "internal_urls": ["http://provider_subscriber_dns_0"],
    },
    "webhook_api": {
      "internal_url": "http://provider_webhook_api",
      "password": "",
      "username": "webhook",
    },
    "zabbix":{
      "admin_password": "",
      "db_password": "",
      "db_root_password": "",
      "db_username": "db_entpnt",
      "password": "",
      "username": "entpnt"
    }
  },
}
