<template>
  <b-col :sm='size'>
    <FormLabel :labelFor='className + "-" + fieldName' :label='_label' :required='required && canUpdate' :sameLine='singleLine'/>
    <select v-if='canUpdate' ref='input' :id='className + "-" + fieldName' :name='fieldName' class='form-control ' :value="value" @input='$emit("input", $event.target.value)' @change='$emit("change")' :required='required'>
      <option v-if='value && !_optionsHasDefault' value=''>Select a {{label ? label : fieldName}}</option>
      <option v-else-if='!_optionsHasDefault' :value='undefined'>Select a {{label ? label : fieldName}}</option>
      <option v-for='option in options' v-bind:key='option.value' :value='option.value'>{{option.display}}</option>
    </select>
    <span v-else ref='input'>
      {{getOptionDisplay(value)}}
    </span>
  </b-col>
</template>

<script>
  import FormLabel from '@/components/form/Label';

  export default {
    name: 'FormInput',
    components: {
      FormLabel
    },
    computed: {
      _label() {
        return this.label ? this.label : this.fieldName.replace(/_/g, ' ').replace(/-/g, ' ');
      },
      _optionsHasDefault(){
        for(var key in this.options){
          if(this.options[key] !== undefined && this.options[key].value === ''){
            return true;
          }
        }
        return false;
      },
    },
    data(){
      return {
      }
    },
    props: {
        canUpdate: {
          default: false,
          type: Boolean
        },
        className: String,
        fieldName: String,
        label: String,
        options: {},
        required: {
          default: true,
          type: Boolean
        },
        singleLine: {
          default: false,
          type: Boolean
        },
        size: {
          default: '6',
          type: String
        },
        value: String
    },
    methods: {
      getOptionDisplay(opt){
        for(var i in this.options){
          if(this.options[i].value === opt){
            return this.options[i].display;
          }
        }
        return '';
      },
      focus() {
        this.$refs.input.focus();
      },
    }
  }
</script>
