export default {
  OLT: {
    display: 'OLT',
    value: 'OLT',
  },
  ONT: {
    display: 'ONT',
    value: 'ONT',
  },    
  VBG: {
    display: 'VBG',
    value: 'VBG',
  },
  SWITCH: {
    display: 'Switch',
    value: 'SWITCH',
  }
}
