<template>
    <form @submit.prevent="handleSubmit" :id="id">
        <slot name='table'>
          <SearchResults v-if='_hasTable' :data='_tableData' :className='className' :fields='_tableFields' :actions='_tableActions' :dataActions='_tableDataActions' @clickAction='handleAction'/>
          <DeleteRecordModal ref='deleteModal' :className='className' :id='_deleteId' @deleted='handleDeleted'/>
        </slot>
        <slot v-if='_defaultVisible'/>
        <slot v-if='_footerVisible' name='footer'>
          <div class='footer-row clearfix'>
            <button v-if='_addVisible' type='button' :class="'pull-right btn btn-' + buttonSize + ' btn-' + addVariant" @click.prevent='handleAdd'>{{addTitle}}</button>
            <button v-if='_cancelVisible' type='button' :class="'pull-right btn btn-' + buttonSize + ' btn-' + cancelVariant" @click="handleCancel">{{cancelTitle}}</button>
            <button v-if='_saveVisible' type="submit" :class="'pull-right btn btn-' + buttonSize + ' btn-' + saveVariant">{{saveTitle}}</button>
          </div>
        </slot>
    </form>
</template>
<script>
  import DeleteRecordModal from '@/components/modal/DeleteRecord';
  import SearchResults from '@/components/search/Results';

  export default {
    name: 'Form',
    components: {
      DeleteRecordModal,
      SearchResults
    },
    computed: {
      _addVisible(){
        return this.addVisibleOverride === null ? this._hasTable ? !this._defaultVisible : false : this.addVisibleOverride;
      },
      _cancelVisible(){
        return this.cancelVisibleOverride === null ? this._hasTable ? this._defaultVisible : false : this.cancelVisibleOverride;
      },
      _defaultVisible(){
        return this.defaultVisibleOverride === null ? this._hasTable ? this.defaultVisible : true : this.defaultVisibleOverride;
      },
      _deleteId(){
        return this.actionEvent && this.actionEvent.item && this.actionEvent.item.id ? this.actionEvent.item.id : '';
      },
      _footerButtonVisible(){
        return this._addVisible || this._cancelVisible || this._saveVisible;
      },
      _footerVisible() {
        return this.footerVisibleOverride === null ? this._footerButtonVisible : this.footerVisibleOverride;
      },
      _hasTable(){
        return this.tableData !== null && (this._tableActions !== null || this._tableFields !== null);
      },
      _saveVisible(){
        return this.saveVisibleOverride === null ? this._hasTable ? this._defaultVisible : true : this.saveVisibleOverride;
      },
      _tableActions(){
        var actions = {
          delete: {title: 'Delete', icon: 'times'},
          view: {title: 'View', icon: 'edit'},
          save: {title: 'Save', icon: 'save'}
        };
        if(this.tableActions){
          for(var i in this.tableActions){
            actions[i] = this.tableActions[i];
          }
        }
        return actions;
      },
      _tableData(){
        return this.tableData && Array.isArray(this.tableData) ? this.tableData : [];
      },
      _tableDataActions(){
        return this.tableDataActions  ? this.tableDataActions : [];
      },
      _tableFields(){
        return  this.tableFields  ? this.tableFields : []
      }
    },
    data() {
      return {
        actionEvent: null,
        defaultVisible: false
      }
    },
    props: {
      addVisibleOverride: {
        default: null,
        type: Boolean
      },
      addTitle: {
        default: 'Add',
        type: String
      },
      addVariant: {
        default: 'primary',
        type: String
      },
      buttonSize: {
        default: 'xs',
        type: String
      },
      cancelVisibleOverride: {
        default: null,
        type: Boolean
      },
      cancelTitle: {
        default: 'Cancel',
        type: String
      },
      cancelVariant: {
        default: 'info',
        type: String
      },
      className: {
        default: '',
        type: String
      },
      defaultVisibleOverride: {
        default: null,
        type: Boolean
      },
      footerVisibleOverride: {
        default: null,
        type: Boolean
      },
      id: {
        default: '',
        type: String
      },
      saveVisibleOverride: {
        default: null,
        type: Boolean
      },
      saveTitle: {
        default: 'Save',
        type: String
      },
      saveVariant: {
        default: 'primary',
        type: String
      },
      tableActions: {
        default: null,
        type: Object
      },
      tableData: {
        default: null,
        type: Array
      },
      tableDataActions: {
        default: null,
        type: Array
      },
      tableFields: {
        default: null,
        type: Array
      },
    },
    methods: {
      cancel(){
        this.defaultVisible = false;
      },
      displayEdit(){
        this.defaultVisible = true;
      },
      handleAction(action, item, index){
        this.actionEvent = {};
        this.actionEvent.action = action;
        this.actionEvent.item = item;
        this.actionEvent.index = index;
        switch(action){
          case 'delete':
            if(item.id){
              this.$refs.deleteModal.show();
            } else {
              this.handleDeleted('');
            }
            break;
          case 'view':
            this.displayEdit();
            // Don't throw error about not having break
            // eslint-disable-next-line
          default:
            this.$emit('clickAction',action, item, index);
        }

      },
      handleAdd(){
        this.$emit('add');
      },
      handleCancel(){
        this.cancel();
        this.$emit('cancel');
      },
      handleDeleted(id){
        this.$emit('deleted', id, this.actionEvent.index);
      },
      handleSubmit(e){
        this.$emit('submit', e);
      }
    }
  }
</script>

<style scoped>
  .footer-row {
    margin: 0px;
  }
  .footer-row > button {
    margin-left: 5px;
  }
</style>
