<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'App',
    data() {
      return {
      }
    },
    mounted() {
      this.verifyLogin();
    },
    updated(){
      this.verifyLogin();
    },
    methods: {
      ...mapGetters('session', [
        'isAuthenticated'
      ]),
      verifyLogin() {
          if(this.$router.currentRoute.name !== 'login' && !this.isAuthenticated()){
            this.$router.replace({name: "login"});
          }
      }
    }
  }
</script>

<style>
</style>
