<template>
  <AlertDismissable header="Success" variant="success" icon="check" :message="message" @dismissed='$emit("dismissed")'/>
</template>

<script>
  import AlertDismissable from '@/components/alerts/Dismissable'

  export default {
    name: 'AlertSuccess',
    components: {
      AlertDismissable
    },
    props: {
      message: String
    },
    data() {
      return {
      }
    },
  }
</script>
