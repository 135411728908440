<template>
  <div>
  <IconButton variant='success' title='Download CSV' icon='file-csv' @click='download()'/>
</div>
</template>

<script>
  import IconButton from '@/components/elements/IconButton';
  import json2csv from 'json2csv';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  export default {
    name: 'CsvDownloadButton',
    components:{
      IconButton
    },
    computed: {
      json2csvFields(){
        var _fields = [];
        for(var i in this.fields){
          var _field = {};
          if(this.fields[i].label){
            _field.label = this.fields[i].label;
          }
          _field.value = this.fields[i].field;
          _fields.push(_field);
        }
        return _fields;
      },
    },
    props: {
        fields: undefined,
        data: undefined,
        downloadName: {
            default: 'export.csv'
        }
    },
    methods: {
        ...mapMutations('messages', {
          error: ADD_ERROR
        }),
        download() {
          if(this.data.length == 0){
            this.error('Error: No data to download');
            return;
          }

          let uri = 'data:text/csv;charset=utf-8,' + '\uFEFF' + encodeURIComponent(json2csv.parse(this.data, {fields: this.json2csvFields}));

          let link = document.createElement('a');

          link.id = 'csv-' + this._uid;
          link.href = uri;

          document.body.appendChild(link);

          document.getElementById(link.id).style.visibility = 'hidden';
          document.getElementById(link.id).download = this.downloadName;

          document.body.appendChild(link);
          document.getElementById(link.id).click();

          setTimeout(function () {
            document.body.removeChild(link);
          });
        }
    }
  }
</script>
