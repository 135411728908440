export default {
  ACTIVE: {
    display: 'Active',
    value: 'ACTIVE'
  },
  COMPLETED: {
    display: 'Completed',
    value: 'COMPLETED'
  },
  INACTIVE: {
    display: 'Inactive',
    value: 'INACTIVE'
  },
  NONE: {
    display: 'No POC',
    value: 'NONE'
  },
}
