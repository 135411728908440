<template>
  <Form @submit.prevent="handleSubmit" id="macaddress-form" :tableData='macaddresses' className='Manufacturermacaddress' :defaultVisibleOverride='showMacAddress' :tableFields='tableFields' :tableDataActions='tableDataActions' :saveVisibleOverride='false' @add='handleAdd' @cancel='hideMacAddress' @clickAction='handleAction' @deleted='load'>
    <div>
      <form>
        <div>
          <b-row class='mb-5'>
            <!--<FormInput className='macaddress' fieldName='macaddress' :v-model='macaddress.macaddress' :canUpdate='true'/>-->
          </b-row>
        </div>
      </form>
    </div>
  </Form>
</template>

<script>
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import abilityTester from '@/config/abilityTester';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import rest from '@/common/tools/Rest';

  export default {
    name: 'MacAddresses',
    components:{
      Form,
    },
    computed: {
      tableDataActions() {
        var resp = [];
        for(var i in this.macaddresses){
          var actions = [];
          actions.push('save');
          if(this.canDeleteMacAddress(this.macaddresses[i].id)){
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    created(){
      this.load();
    },
    data() {
      return {
        macaddresses: [],
        selectedMacAddressIndex: null,
        showMacAddress: false,
        tableFields: [
          {key: 'macaddress', label: 'Mac Address', displayStatus: true, type: 'input'},
          {key: 'Action', class: 'text-center'}
        ],
      }
    },
    props: {
      manufacturer_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canDeleteMacAddress(id){
        return abilityTester.can('delete', 'Manufacturermacaddress', {macaddress_id: id});
      },
      load(){
        var args = {
          'filter': 'Macaddresses.status neq INACTIVE',
          'loading': 'Macaddresses'
        }
        this.$emit('entrypoint_wait_show', 'Loading Macaddresses...');
        rest.get('manufacturer/' + this.manufacturer_id,'manufacturer', args).then(response => {
            this.macaddresses = response.Macaddresses ? response.Macaddresses : [];
            this.selectedMacAddressIndex = null;
            this.showMacAddress = false;
          }).catch(e=>{
            this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      save(index){
        this.macaddresses[index].manufacturer_id = this.manufacturer_id;
        var data = JSON.parse(JSON.stringify(this.macaddresses[index]));
        var saveMacAddress = (data) => {
          this.$emit('entrypoint_wait_show', 'Saving...');
          rest.save('/manufacturermacaddress', 'Manufacturermacaddress', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.macaddresses[index], k, val);
            }
            this.$emit('entrypoint_wait_hide');
            this.alertSuccess('Mac Address Saved');
            this.$emit('saved');
          }).catch(e => {
            this.$emit('entrypoint_wait_hide');
            this.error(rest.getErrorMessage(e));
          })
        }
        saveMacAddress(data);
      },

      displayMacAddress(index){
        this.selectedMacAddressIndex = index;
        this.showMacAddress = true;
      },
      handleAction(action, item, index){
        switch(action){
          case 'delete':
            this.displayDeleteModal(item.id);
            break;
          case 'save':
            this.save(index)
            break;
        }

      },
      handleAdd(){
        this.displayMacAddress(this.macaddresses.push({'id': ''}) - 1);
      },
      handleSubmit(){
        this.save();
      },
      hideMacAddress(){
        this.showMacAddress = false;
      },
    },
  }
</script>
