<template>
  <b-col :sm='size'>
    <b-row class='inputArrayRow'>
      <b-col cols='10' align-self='center' class='inputArrayLabelCol'>
        <FormLabel v-if='useLabel' :labelFor='className + "-" + fieldName' :label='_label' :required='required && canUpdate'/>
      </b-col>
      <b-col cols='1' align-self='center' class='addButtonCol'>
        <IconButton variant='success' :title='"Add " + _label.toUpperCase()' icon='plus' @click='addValue()'/>
      </b-col>
    </b-row>
    <b-row v-for='(val, i) in value' v-bind:key='i' class='inputArrayRow'>
      <b-col cols='10' align-self='center' class='inputArrayCol'>
        <FormInput :autocomplete='autocomplete' :canUpdate='canUpdate' :className='className' :fieldName='fieldName + "_" + i' @input='handleInput(i, $event)' :label='label' :placeholder='placeholder' :randomExclude='randomExclude' :randomExcludeSimilar='randomExcludeSimilar' :randomLength='randomLength' :randomTitle='randomTitle' :randomUseNumbers='randomUseNumbers' :randomUseSymbols='randomUseSymbols' :required='required' :size='inputSize' :type='type' :useLabel='false' :useRandom='useRandom' :value='val'/>
      </b-col>
      <b-col cols='1' align-self='center' class='removeButtonCol'>
        <IconButton variant='danger' :title='"Remove " + _label.toUpperCase()' icon='times' @click='removeIndex(i)'/>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
  import FormInput from '@/components/form/Input';
  import FormLabel from '@/components/form/Label';
  import IconButton from '@/components/elements/IconButton';

  export default {
    name: 'FormInputArray',
    components: {
      FormInput,
      FormLabel,
      IconButton
    },
    computed: {
      _label() {
        return this.label ? this.label : this.fieldName.replace(/_/g, ' ').replace(/-/g, ' ');
      }
    },
    data(){
      return {
      }
    },
    props: {
      autocomplete: {
        default: 'on',
        type: String
      },
      canUpdate: {
        default: false,
        type: Boolean
      },
      className: String,
      fieldName: String,
      inputSize: {
        default: '12',
        type: String
      },
      label: String,
      placeholder: String,
      randomExclude: {
        default: '',
        type: String
      },
      randomExcludeSimilar: {
        default: false,
        type: Boolean
      },
      randomLength: {
        default: 18,
        type: Number
      },
      randomTitle: {
        default:'Generate Random Value',
        type: String
      },
      randomUseNumbers: {
        default: true,
        type: Boolean
      },
      randomUseSymbols: {
        default: false,
        type: Boolean
      },
      required: {
        default: true,
        type: Boolean
      },
      size: {
        default: '6',
        type: String
      },
      type: {
        default: 'text',
        type: String
      },
      useLabel: {
        default: true,
        type: Boolean
      },
      useRandom: {
        default: false,
        type: Boolean
      },
      value: [Array]
    },
    methods: {
      addValue(){
        var newVal = this.value;
        newVal.push('');
        this.updateValue(newVal);
      },
      handleInput(index, value){
        var newVal = this.value;
        newVal[index] = value;
        this.updateValue(newVal);
      },
      removeIndex(index){
        var newVal = this.value;
        newVal.splice(index,1);
        this.updateValue(newVal);
      },
      updateValue(newVal){
        this.$emit('input', newVal);
      }
    }
  }
</script>

<style scoped>
  .inputArrayRow {
    padding-left: 0px;
    padding-right: 0px;
  }
  .inputArrayCol{
    padding: 0px;
  }
  .addButtonCol, .removeButtonCol {
    padding: 0px;
  }
</style>