export default {
  ADMIN: {
    display: 'Administrator',
    value: 'ALL'
  },
  SALES: {
    display: 'Sales',
    value: 'SALES'
  }
}
