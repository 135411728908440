<template>
  <span v-if='sameLine' class='bold text'>{{label}}: </span>
  <label v-else class='label' :for='labelFor'>
    {{label}}
    <span v-if='required'> *</span>
  </label>
</template>

<script>
  export default {
    name: 'FormLabel',
    data(){
      return {
      }
    },
    props: {
        label: String,
        labelFor: String,
        sameLine: {
          default: false,
          type: Boolean
        },
        required: {
          default: true,
          type: Boolean
        },
    },
  }
</script>

<style scoped>
  .label, .bold{
    font-weight: bold !important;
    font-size: 75%;
  }
</style>
