<template>
  <div>
    <div id="dashboard">
      <Navigation></Navigation>
      <div class="nav-container">
        <div id="content" class="container-fluid" style="opacity: 1;">
          <router-view @entrypoint_wait_show='showWait' @entrypoint_wait_hide='hideWait'></router-view>
        </div>
      </div>
      <NavigationFooter></NavigationFooter>
    </div>
    <AlertContainer/>
    <Wait v-if='text' :text='text'/>
  </div>
</template>
<script>
  import AlertContainer from '@/components/alerts/Container';
  import Navigation from '@/components/navigation/Navigation.vue';
  import NavigationFooter from '@/components/navigation/Footer.vue';
  import Wait from '@/components/entrypoint/Wait';

  export default {
    name: 'Dashboard',
    components: {
      AlertContainer,
      NavigationFooter,
      Navigation,
      Wait,
    },
    data() {
      return {
        text: '',
      };
    },
    methods: {
      hideWait(){
        this.text = '';
      },
      showWait(text){
        this.text = text;
      }
    }
  }
</script>

<style scoped>
  .nav-container{
    margin-top: 20px;
  }
</style>
