
<script>
  import { BTab } from 'bootstrap-vue';

  export default {
    name: 'Tab',
    extends: BTab,
    computed: {
      tabClasses() {
          var classes = this._BTabComputedTabClasses();
          if(this.show){
            // fix issue with fade not working
            classes.push('in');
          }
          return classes;
        }
    },
    methods: {
      _BTabComputedTabClasses: BTab.extendOptions.computed.tabClasses
    }
  }
</script>
