<template>
  <div>
    <b-row class='mb-3'>
      <b-col sm='8'>
        <h3>
          <font-awesome-icon icon='file-invoice-dollar' />
          Billing
        </h3>
      </b-col>
    </b-row>
    <b-row class='mb-3'>
      <form>
          <b-container>
            <b-row class='mb-5' >
              <b-col sm='11'>
                <FormInput className='billing' fieldName='start_date' size="4" type='date' v-model='start_date' :canUpdate='true' :singleLine='true'/>
                <FormInput className='billing' fieldName='end_date' size="4" type='date' v-model='end_date' :canUpdate='true' :singleLine='true'/>
              </b-col>
              <b-col sm='1'>
                <button type='button' class='btn btn-xs btn-primary' style='margin-top: 25px;' @click.prevent='search'>Search</button>
              </b-col>
            </b-row>
            <b-row class='mb-5' v-if='billingDataIsEmpty'>
              <b-col>No results for selected dates.</b-col>
            </b-row>
            <b-row class='mb-5' v-else>
              <b-col sm='11'>
                <b-table striped hover :fields='tableFields' :items='billingData'/>
              </b-col>
              <b-col sm='1'>
                <CsvDownloadButton :fields='fields' :data='billingData' :downloadName='csvDownloadName'/>
              </b-col>
            </b-row>
          </b-container>
      </form>
    </b-row>
  </div>
</template>

<script>
  import CsvDownloadButton from '@/components/elements/export/CsvDownloadButton';
  import FormInput from '@/components/form/Input';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import rest from '@/common/tools/Rest';
  import Utils from '@/common/tools/Utils';

  export default {
    name: 'Billing',
    components: {
      CsvDownloadButton,
      FormInput,
    },
    computed: {
      billingDataIsEmpty(){
        return Object.keys(this.billingData).length === 0;
      },
      csvDownloadName(){
        return this.start_date + '_' + this.end_date + '_' + 'billing.csv';
      },
      fields(){
        var fields = [];
        if(!this.billingDataIsEmpty){
          for(var field in this.billingData[0]){
            fields.push({
              field: field,
              label: Utils.string.keyToDescr(field)
            })
          }
        }

        return fields;
      },
      tableFields(){
        var fields = [];
        for(var i in this.fields){
          var field = this.fields[i];
          fields.push({
            key: field.field,
            label: field.label
          })
        }
        return fields;
      }
    },
    created() {
        this.setDefaults();
        this.search();
    },
    data() {
      return {
        end_date: '',
        start_date: '',
        billingData: []
      }
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      search(){
        var args = {
          'start_date': Utils.date.format.ymdToMdy(this.start_date),
          'end_date': Utils.date.format.ymdToMdy(this.end_date),
        }
        this.$emit('entrypoint_wait_show', 'Loading Billing Info...');
        rest.get('provider/billing','provider', args).then(response => {
            this.billingData = response ? response : [];
          }).catch(e=>{
            this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      setDefaults(){
        var curDate = new Date();
        var firstDayPrevMonth = new Date(curDate.getFullYear(), curDate.getMonth() - 1, 1);
        var lastDayPrevMonth = new Date(curDate.getFullYear(), curDate.getMonth(), 0);
        this.$set(this, 'start_date', Utils.date.format.ymd(firstDayPrevMonth));
        this.$set(this, 'end_date', Utils.date.format.ymd(lastDayPrevMonth));
      },
    }
  }
</script>

<style scoped>
</style>
