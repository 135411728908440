import { AbilityBuilder } from '@casl/ability'
import userRoles from '@/common/Enums/UserRole';

function subjectName(subject){
  if(!subject || typeof subject === 'string'){
    return subject;
  }
  return subject.subjectName;
}

export default function(state){
  state = state ? state : {};
  var user = state.user ? state.user : {};
  return AbilityBuilder.define({subjectName}, (can) => {
    switch(user.role){
      case userRoles.ADMIN.value:
        can(['delete', 'nav', 'read'], 'All', ['provider', 'user', 'manufacturer', 'provider-billing', 'report', 'signature', 'version']); //All has to be capitalized or it will not limit correctly
        can(['create', 'delete', 'nav', 'read', 'update'], 'api');
        can(['create', 'delete', 'nav', 'read', 'update'], 'Manufacturermacaddress');
        can(['create', 'delete', 'nav', 'read', 'update'], 'contact', ['first_name', 'last_name', 'email', 'phone']);
        can(['create', 'read', 'update'], 'user-contact');
        can(['create', 'delete', 'read', 'update'], 'provider');
        can(['read', 'update'], 'provider-custom_configuration');
        can(['read', 'update'], 'provider-detailed_configuration');
        can(['create', 'read', 'update'], 'provider-location');
        can(['read'], 'provider-billing');
        can(['create', 'delete', 'nav', 'read', 'update'], 'user');
        can(['create', 'delete', 'nav', 'read', 'update'], 'manufacturer');
        can(['create', 'delete', 'nav', 'read', 'update'], 'product');
        can(['create', 'delete', 'nav', 'read', 'update'], 'signature');
        can(['create', 'delete', 'nav', 'read', 'update'], 'systemstats');
        can(['create', 'delete', 'nav', 'read', 'update'], 'version');
        break;
      case userRoles.SALES.value:
        can(['delete', 'nav', 'read'], 'All', ['provider', 'provider-billing']); //All has to be capitalized or it will not limit correctly
        can(['nav', 'read'], 'All', ['systemstats']); //All has to be capitalized or it will not limit correctly
        can(['read'], 'user-contact');
        can(['update'], 'user-contact', ['first_name', 'last_name', 'email', 'phone']);
        can(['create', 'delete', 'read', 'update'], 'provider', ['configuration_json', 'created_at', 'description', 'id', 'Location', 'location_id', 'name', 'software_versions', 'status', 'type', 'updated_at']);
        can(['read', 'update'], 'provider-custom_configuration');
        can(['create', 'read', 'update'], 'provider-location');
        can(['read'], 'provider-billing');
        can(['read'], 'user', {user_id: user.id});
        can(['update'], 'user', ['password', 'settings', 'username', 'Contact'], {user_id: user.id});
        break;
    }
  });
}
