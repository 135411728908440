<template>
  <nav id="nav">
    <HeaderView ></HeaderView>
    <SidebarView ref='sidebarView'></SidebarView>
  </nav>
</template>
<script>
  import HeaderView from '@/components/navigation/Header.vue';
  import SidebarView from '@/components/navigation/Sidebar.vue';

  export default {
    name: 'Navigation',
    components: {
      HeaderView,
      SidebarView
    },
    data() {
      return {};
    },
  }
</script>

<style scoped>
  .navbar {
    background: #fff url(../../assets/theme/default/img/layout/navbar_top.gif) -1px 0 repeat-x;
    border: none;
    border-bottom: solid 1px #d1d3d4;
    border-radius: 0;
    box-shadow: none;
    height: 50px;
    margin-bottom: 5px;
    padding-top: 6px;
    padding-right: 8px;
    padding-left: 0px;
    padding-bottom: 0px;
    display: block;
}
</style>
