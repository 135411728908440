<template>
  <footer class="footer">
    <div class="container">
      <p class="text-muted credit">
        © Entry Point Networks {{  new Date().getFullYear() }}
        <span class="small">{{ version }}</span>
      </p>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'NavigationFooter',
    data() {
      return {
        version: "(v1.0.0.2018121.001)"
      }
    },
  }
</script>

<style scoped>
.footer {
    bottom: 0;
    height: 30px;
    position: fixed;
    text-align: center;
    width: 100%;
}
</style>
