const urlMapping = [
	{
		hostname: 'ansible',
		name: 'Ansible URL',
		path: 'services.ansible.external_url'
	},
	{
		hostname: 'dns1',
		name: 'Management DNS 1 URL',
		path: 'local.services.management_dns.instances.operator_management_dns_0.external_url'
	},
	{
		hostname: 'dns2',
		name: 'Management DNS 2 URL',
		path: 'local.services.management_dns.instances.operator_management_dns_1.external_url'
	},
	{
		hostname: 'eventmanager',
		name: 'Event Manager URL',
		path: 'local.urls.event_manager_url'
	},
	{
		hostname: 'flowops',
		name: 'FlowOps URL',
		path: 'local.urls.flowops_url'
	},
	{
		hostname: 'api',
		name: 'FlowOps Proxy URL',
		path: 'local.urls.flowops_proxy_url'
	},
	{
		hostname: 'management',
		name: 'Management URL',
		path: 'local.urls.management_url'
	},
	{
		hostname: 'ntp1',
		name: 'Management NTP 1 URL',
		path: 'local.services.management_ntp.instances.operator_management_ntp_0.external_url'
	},
	{
		hostname: 'ntp2',
		name: 'Management NTP 2 URL',
		path: 'local.services.management_ntp.instances.operator_management_ntp_1.external_url'
	},
	{
		hostname: 'radius',
		name: 'Management Radius URL',
		path: 'services.management_radius.instances.operator_management_radius_0.external_url'
	},
	{
		hostname: 'repo',
		name: 'Base Repository URL',
		path: 'urls.repo_base_url'
	},
	{
		hostname: 'management',
		includeProtocol: false,
		name: 'SSH Domain',
		path: 'local.constants.ssh_domain'
	},
]
export default urlMapping;