<template>
  <div>
		<div class='wait-overlay'></div>
		<h1 class='wait-text'>{{text}}</h1>
  </div>
</template>

<script>
  export default {
    name: 'Wait',
    data(){
      return {
      }
    },
    props: {
        text: String,
    },
  }
</script>

<style scoped>
	.wait-overlay {
		background: #e9e9e9;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.5;
		z-index: 10000;
	}
	.wait-text{
		text-align: center;
		padding-top: 200px !important;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10000;
	}
</style>
