<template>
  <b-col sm='12' :class='containerClasses' class='dynamicContainer'>
    <span v-if='hasChildren' class='mb-5 expanded-row pull-right'>
      <IconButton v-if='expanded' icon='codicon:collapse-all' variant='outline-dark' @click='setExpanded(false)' class='expandable' fontSize='35px'  :noFocusOutline='true' :showBorder='false' :title='"Collapse All: " + levelDescr'/>
      <IconButton v-else icon='codicon:expand-all' variant='outline-dark' @click='setExpanded(true)' class='expandable' fontSize='35px'  :noFocusOutline='true' :showBorder='false' :title='"Expand All: " + levelDescr'/>
    </span>
    <b-row v-for='(row, i) in rows' v-bind:key='i' :class='"dynamicContainer" + (isArray ? " arrayElementRow" : "")'>
      <b-col v-for='(k, j) in row' v-bind:key='j' class='dynamicContainer'>
        <b-col sm='12' v-if='typeof value[k] == "object"' class='dynamicContainer'>
          <span v-if='expandable' class='nestedButtons'>
            <IconButton v-if='nestedHidden[k]' icon='plus' variant='outline-dark' @click='setNestedHidden(k, false)' class='expandable' :noFocusOutline='true'/>
            <IconButton v-else icon='minus' variant='outline-dark' @click='setNestedHidden(k, true)' class='expandable' :noFocusOutline='true'/>
          </span>
          <FormLabel v-if='!isArray' :label='k' :required='false' :sameLine='true' style='margin-right: 10px;'/>
          <IconButton v-if='allowAddRemove' variant='danger' :title='"Remove " + k.toUpperCase()' icon='times' @click='removeField(k)'/>
          <DynamicForm v-if='!nestedHidden[k]' :value='value[k] ? value[k] : {}' @input='handleInput(k, $event)' class='nestedForm' :allowAddRemove='allowAddRemove' :canUpdate='canUpdate' :className='className ? className + "-" + k : k' :expandedDefault='expandedDefault' :expandable='expandable' :level='level + 1' :levelName='k' :required='required'/>
        </b-col>
        <FormCheckBox v-else-if='typeof value[k] == "boolean"' @input='handleInput(k, $event)' v-model='value[k]' :className='className' :small='false' :fieldName='k' :canUpdate='canUpdate' :size='allowAddRemove ? "8" : "4"' :required='required'/>
        <FormInput v-else v-model='value[k]' :type='isPasswordField(k) ? "password" : "text"' @input='handleInput(k, $event)' :className='className' :fieldName='k' :canUpdate='canUpdate' :size='allowAddRemove ? "8" : "12"' :autocomplete="isPasswordField(k) ? 'new-password' : 'on'" :required='required' :useLabel='!isArray' :useRandom='isRandomField(k)'/>
        <b-col v-if='allowAddRemove && typeof value[k] !=="object"' sm='4' class='removeInput'>
          <IconButton variant='danger' :title='"Remove " + k.toUpperCase()' icon='times' @click='removeField(k)'/>
        </b-col>
      </b-col>
    </b-row>
    <b-row v-if='allowAddRemove'>
      <FormInput v-if='!isArray' ref='newFieldName' v-model='newFieldName' @input='newFieldError = false' :class='newFieldError ? "inputError" : ""' :canUpdate='canUpdate' :required='false' style='margin-left: 15px' placeholder='Name of Field to Add' :useLabel='false'/>
      <span v-else style='margin-left: 25px'></span>
      <b-col sm='5' style='margin: auto 0px; padding-left: 0px;'>
        <IconButton title='Add Object' icon='cube' class='addBtn' @click='addField("object")'/>
        <IconButton title='Add Array' icon='layer-group' class='addBtn' @click='addField("array")'/>
        <IconButton v-if='!isArray' title='Add Boolean' icon='check' class='addBtn' @click='addField("boolean")'/>
        <IconButton title='Add Value' icon='edit' class='addBtn' @click='addField("value")'/>
      </b-col>
    </b-row>
    <div class='nestedEnd'/>
  </b-col>
</template>

<script>
  import FormCheckBox from '@/components/form/CheckBox';
  import FormInput from '@/components/form/Input';
  import FormLabel from '@/components/form/Label';
  import IconButton from '@/components/elements/IconButton';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import Vue from 'vue';


  export default {
    name: 'DynamicForm',
    components: {
      FormCheckBox,
      FormInput,
      FormLabel,
      IconButton
    },
    computed: {
      containerClasses(){
        var classes = this.level > 0 ? "" : "topLevel";
        if(this.hasChildren){
          classes += " hasChildren"
        }
        return classes;
      },
      hasChildren(){
        for(var key in this.value){
          if(typeof this.value[key] == "object"){
            return true;
          }
        }
        return false;
      },
      isArray(){
        return typeof this.value == 'object' && Array.isArray(this.value);
      },
      levelDescr(){
        var descr = (this.levelName) ? this.levelName : this.className;
        return descr.toUpperCase().replace(/_|-/g, " ")

      },
      rows() {
        var rows = [];
        var nextRow = [];
        var colSize = 0;
        for(var key in this.value){
          var curColSize = Number.parseInt(this.getColSize(this.value[key]));
          if(colSize + curColSize <= 12){
            colSize += curColSize;
          } else {
            rows.push(nextRow);
            nextRow = [];
            colSize = curColSize;
          }
          nextRow.push(key);
          if(this.allowAddRemove || colSize >= 12){
            rows.push(nextRow);
            nextRow = [];
            colSize = 0;
          }
        }
        if(nextRow.length > 0){
          rows.push(nextRow);
        }
        return rows;
      }
    },
    data(){
      return {
        expanded: this.expandedDefault,
        newFieldError: false,
        newFieldName: '',
        nestedHidden: {}
      }
    },
    props: {
      allowAddRemove: {
        default: true,
        type: Boolean
      },
      canUpdate: {
        default: false,
        type: Boolean
      },
      className: {
        default: '',
        type: String
      },
      expandedDefault: {
        default: false,
        type: Boolean
      },
      expandable: {
        default: true,
        type: Boolean
      },
      level: {
        default: 0,
        type: Number
      },
      levelName: {
        default: '',
        type: String
      },
      required: {
        default: true,
        type: Boolean
      },
      value: {}
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      addField(type){
        if(this.isArray){
          this.newFieldName =  this.value.length;
        } else if(!this.newFieldName){
            this.error('Please enter the name of the new field.');
            this.newFieldError = true;
            return;
        }
        if(this.value[this.newFieldName] !== undefined){
          this.error('Field already exists.');
          this.newFieldError = true;
          return;
        }
        var val = '';
        switch(type){
          case 'object':
            val = {};
            break;
          case 'array':
            val = [];
            break;
          case 'boolean':
            val = false;
            break;
        }
        this.handleInput(this.newFieldName, val)
        this.newFieldError = false;
        this.newFieldName = '';
      },
      getColSize(val){
        switch(typeof val){
          case 'object':
            return '12';
          default:
           return '6';
        }
      },
      handleInput(key, input){
        if(typeof(input) != 'object' || !input.value || !input.path){
          input = {"value": input, "path": key}
        } else {
          input.path = key + '.' + input.path;
        }

        this.value[key] = input.value;
        this.valueChanged(input.path);
      },
      isPasswordField(fieldName){
        return fieldName.toLowerCase().indexOf("password") >= 0 || fieldName.toLowerCase().replace(/-/g, "_").indexOf("secret") >= 0
      },
      isRandomField(fieldName){
        fieldName = fieldName.toLowerCase();
        return this.isPasswordField(fieldName) || fieldName.replace(/-/g, "_").indexOf("api_key") >= 0 || fieldName == 'salt'
      },
      removeField(fieldName){
        Vue.delete(this.value, fieldName);
        this.valueChanged(fieldName);
      },
      setExpanded(val){
        this.expanded = val;
        for(var key in this.value){
          this.setNestedHidden(key, !val)
        }
      },
      setNestedHidden(fieldName, val){
        this.$set(this.nestedHidden, fieldName, val);
      },
      valueChanged(path){
        this.$emit('input', { value: this.value, path: path});
      }
    },
    mounted(){
      this.setExpanded(this.expanded);
    }
  }
</script>

<style>
  .inputError > input {
    box-shadow: inset 0 1px 1px red, 0 0 8px red;
    border-color: red;
}
</style>

<style scoped>
  .addBtn {
    margin-left: 5px;
  }
  .expanded-row {
    margin-right: 10px;
    margin-bottom: 0px !important;
    margin-top: -35px;
    padding: 0px;
  }
  .expandable {
    margin-right: 10px;
  }
  .dynamicContainer {
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .nestedButtons {
    margin-left: -10px;
  }
  .nestedForm {
    border-left: dotted #ddd;
    margin-bottom: 0px;
    margin-top: 5px;
    padding-bottom: 0px;
    padding-left: 15px;
  }
  .nestedForm > .row {
    padding-left: 0px;
  }
  .nestedForm > .nestedEnd {
    border-left: solid white;
    border-top: dotted #ddd;
    bottom: 0px;
    height: 15px;
    left: -3px;
    position:absolute;
    width: 15px;
  }
  .removeInput {
    margin-top: 25px;
    padding-left: 0px;
  }
  .arrayElementRow {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .arrayElementRow > .col > .removeInput {
    margin-top: 6px;
  }
  .topLevel > .row, .topLevel > .row > .col {
    padding-left: 0px;
  }
  .topLevel {
    float: none;
  }
  .topLevel.hasChildren {
    margin-top: 30px;
  }
</style>
