<template>
  <Form @submit.prevent="handleSubmit" id="api-form" :tableData='apis' className='api' :defaultVisibleOverride='showApi' :tableFields='tableFields' :tableDataActions='tableDataActions' @add='handleAdd' @cancel='hideApi' @clickAction='handleAction' @deleted='load'>
    <ApiEdit v-if='apis[selectedApiIndex]' ref='api' :api='apis[selectedApiIndex]' @saved='hideApi' :provider_id='provider_id' @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
  </Form>
</template>

<script>
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import abilityTester from '@/config/abilityTester';
  import apiAccessTypesEnum from '@/common/Enums/Api/AccessType';
  import ApiEdit from '@/components/api/Edit';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import rest from '@/common/tools/Rest';

  export default {
    name: 'Apis',
    components:{
      ApiEdit,
      Form,
    },
    computed: {
      tableDataActions() {
        var resp = [];
        for(var i in this.apis){
          var actions = [];
          actions.push('view');
          if(this.canDeleteApi(this.apis[i].id)){
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    created(){
      this.load();
    },
    data() {
      return {
        apis: [],
        selectedApiIndex: null,
        showApi: false,
        tableFields: [
          {key: 'uri', label: 'URI', displayStatus: true},
          {key: 'access_type', displayTransform: apiAccessTypesEnum},
          {key: 'Action', class: 'text-center'}
        ],
      }
    },
    props: {
      provider_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canDeleteApi(id){
        return abilityTester.can('delete', 'api', {api_id: id});
      },
      load(){
        var args = {
          'filter': 'Apis.status neq INACTIVE',
          'loading': 'Apis'
        }
        this.$emit('entrypoint_wait_show', 'Loading Apis...');
        rest.get('provider/' + this.provider_id,'provider', args).then(response => {
            this.apis = response.Apis ? response.Apis : [];
            this.selectedApiIndex = null;
            this.showApi = false;
          }).catch(e=>{
            this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      displayApi(index){
        this.selectedApiIndex = index;
        this.showApi = true;
      },
      handleAction(action, item, index){
        switch(action){
          case 'delete':
            this.displayDeleteModal(item.id);
            break;
          case 'view':
            this.displayApi(index);
            break;
        }

      },
      handleAdd(){
        this.displayApi(this.apis.push({'id': ''}) - 1);
      },
      handleSubmit(){
        this.$refs.api.save();
      },
      hideApi(){
        this.showApi = false;
      },
    },
  }
</script>
