const urlMapping = [
	{
		hostname: 'dns1',
		name: 'Management DNS 1 URL',
		path: 'services.management_dns.instances.provider_management_dns_0.external_url'
	},
	{
		hostname: 'dns2',
		name: 'Management DNS 2 URL',
		path: 'services.management_dns.instances.provider_management_dns_1.external_url'
	},
	{
		hostname: 'flowops',
		name: 'FlowOps URL',
		path: 'local.urls.flowops_url'
	},
	{
		hostname: 'management',
		name: 'Management URL',
		path: 'local.urls.management_url'
	},
	{
		hostname: 'ntp1',
		name: 'Management NTP 1 URL',
		path: 'services.management_ntp.instances.provider_management_ntp_0.external_url'
	},
	{
		hostname: 'ntp2',
		name: 'Management NTP 2 URL',
		path: 'services.management_ntp.instances.provider_management_ntp_1.external_url'
	},
	{
		hostname: 'webhook',
		name: 'Webhook API URL',
		path: 'local.urls.webhook_url'
	},
]
export default urlMapping;