import Vue from 'vue'
import {ADD_ERROR, ADD_SUCCESS, REMOVE_ERROR, REMOVE_SUCCESS} from '@/store/MutationTypes';

export default {
  namespaced: true,
  state: {
    errors: {},
    successes: {}
  },
  mutations: {
    [ADD_ERROR] (state, msg) {
      Vue.set(state.errors, Date.now(), msg);
    },
    [ADD_SUCCESS] (state, msg) {
      Vue.set(state.successes, Date.now(), msg);
    },
    [REMOVE_ERROR] (state, i){
      Vue.delete(state.errors, i);
    },
    [REMOVE_SUCCESS] (state, i){
      Vue.delete(state.successes, i);
    }
  }
}
