<template>
  <div>
    <div v-for='section in configFormWithColumns' v-bind:key='section.section_name'>
      <template :v-if='section.fields && Array.isArray(section.fields)'>
        <CollapsibleSection :collapsed='sectionsCollapsedState[section.section_name]' @setCollapsedState='setSectionCollapsedState(section.section_name, $event)' :title='section.section_name'>
          <b-row class='mb-5' v-for='(row, rowIndex) in section.fields' v-bind:key='rowIndex'>
            <template v-for='col in row'>
              <FormSelect v-if='col.type && col.type == "select"' :canUpdate='true' className='provider-customconfig' :fieldName='col.name' @input='handleInput(col.path, $event)' :options='col.options && typeof col.options == "object" ? col.options : {}' :required='false' :value='getField(col.path)' v-bind:key='col.name'/>
              <FormCheckBox v-else-if='col.type && col.type == "checkbox"' :canUpdate='true' className='provider-customconfig' :fieldName='col.name' @input='handleInput(col.path, $event)' :required='false' size='2' :small='false' :type='col.type ? col.type : "input"' :useRandom='col.type && col.type == "password" ? true : false' :value='getField(col.path, col.default === undefined ? false : col.default)' v-bind:key='col.name'/>
              <FormIncrementNumber v-else-if='col.type && col.type == "increment_number"' :canUpdate='true' className='provider-customconfig' :fieldName='col.name' @input='handleIncrementNumberInput(col.path, col.default_value ? col.default_value : "", col.baseValue ? col.baseValue : col.base_value ? col.base_value : 0, $event)' :min='col.min' :max='col.max' :placeholder='col.placeholder' :required='false' :value='getFieldIncrementNumber(col.path, col.default_value ? col.default_value : 0)' v-bind:key='col.name'/>
              <FormInputArray v-else-if='col.type && col.type == "array"' :canUpdate='true' className='provider-customconfig' :fieldName='col.name' @input='handleInput(col.path, $event)' :placeholder='col.placeholder' :required='false' :type='col.type ? col.type : "input"' :value='getField(col.path, [])' v-bind:key='col.name'/>
              <FormInput v-else :canUpdate='true' className='provider-customconfig' :fieldName='col.name' @input='handleInput(col.path, $event)' :placeholder='col.placeholder' :required='false' :type='col.type ? col.type : "input"' :useRandom='col.type && col.type == "password" ? true : false' :value='getField(col.path)' v-bind:key='col.name'/>
            </template>
          </b-row>
        </CollapsibleSection>
      </template>
    </div>
  </div>
</template>

<script>
  import CollapsibleSection from '@/components/elements/CollapsibleSection';
  import CustomConfigFormService from '@/config/provider/templates/custom/FormFieldsService';
  import CustomConfigFormTransport from '@/config/provider/templates/custom/FormFieldsTransport';
  import FormCheckBox from '@/components/form/CheckBox';
  import FormIncrementNumber from '@/components/form/IncrementNumber';
  import FormInput from '@/components/form/Input';
  import FormInputArray from '@/components/form/InputArray';
  import FormSelect from '@/components/form/Select';
  import providerTypesEnum from '@/common/Enums/ProviderType';
  import SmtpProtocolEnum from '@/common/Enums/SmtpProtocol';
  import Utils from '@/common/tools/Utils';

  export default {
    name: 'ProviderCustomConfig',
    components:{
      CollapsibleSection,
      FormCheckBox,
      FormIncrementNumber,
      FormInput,
      FormInputArray,
      FormSelect,
    },
    computed: {
      config() {
        return this.configuration ? this.configuration : {};
      },
      configFormWithColumns() {
        const COLS_PER_ROW = 2;
        let form = this.customConfigForm;
        for(let i in form){
          if(form[i].fields && Array.isArray(form[i].fields)){
            let fields = [];
            let row = [];
            for(let j in form[i].fields){
              if(j % COLS_PER_ROW == 0 && row.length > 0){
                fields.push(row.slice());
                row = [];
              }
              row.push(form[i].fields[j]);
            }
            if(row.length > 0){
              fields.push(row);
            }
            form[i].fields = fields;
          }
        }
        return form;
      },
      customConfigForm(){
        let form = {};
        switch(this.providerType){
          case providerTypesEnum.SERVICE.value:
            form = CustomConfigFormService;
            break;
          case providerTypesEnum.TRANSPORT.value:
          default:
            form = CustomConfigFormTransport;
        }
        return JSON.parse(JSON.stringify(form)); // Don't modify original array
      },
    },
    data() {
      return {
        sectionsCollapsedState: {},
        smtpProtocols: SmtpProtocolEnum
      }
    },
    props: {
      canUpdate: {
        default: false,
        type: Boolean
      },
      configuration: {},
      providerType: {
        default: providerTypesEnum.TRANSPORT.value,
        type: String
      }
    },
    methods: {
      handleIncrementNumberInput(path, defaultValue, baseValue, value){
        var newVal = value;
        if(Array.isArray(defaultValue)){
          newVal = this.getField(path, defaultValue);
          while (value > newVal.length){
            newVal.push(baseValue + newVal.length);
          }
          while (value < newVal.length){
            newVal.pop();
          }
        }
        this.updateConfigPath(path, newVal);
      },
      handleInput(path, value){
        var newConfig = this.config;
        switch(path){
          default:
            Utils.setObjectValueByPath(newConfig, path, value);
        }
        this.updateConfig(newConfig, path);
      },
      getField(path, defaultVal = ''){
        let fieldPath = path;
        switch(path){
          case 'subscriber_portal_hostname':
            fieldPath = 'local.urls.subscriber_url';
            break;
        }

        let field = Utils.getObjectValueByPath(this.config, fieldPath, defaultVal)

        switch(path){
          case 'subscriber_portal_hostname':
            field = Utils.urls.toDomain(field);
            break;
        }
        return field;
      },
      getFieldIncrementNumber(path, defaultVal = 0){
        var field = Utils.getObjectValueByPath(this.config, path, defaultVal);
        if(Array.isArray(defaultVal)){
          field = field.length;
        } else if(typeof field === 'string'){
          field = Number(field);
          field = isNaN(field) ? defaultVal : field;
        }
        return field;
      },
      setSectionCollapsedState(section, collapsed){
        this.$set(this.sectionsCollapsedState, section, collapsed);
      },
      updateConfig(newConfig, path){
        this.$emit('updateConfig', { value: newConfig, path: path});
      },
      updateConfigPath(path, newVal){
        var newConfig = this.config;
        Utils.setObjectValueByPath(newConfig, path, newVal);
        this.updateConfig(newConfig, path);
      },
    },
  }
</script>