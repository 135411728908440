export default {
  INSECURE: {
    display: 'Insecure',
    value: ''
  },
  SSL: {
    display: 'SSL',
    value: 'ssl'
  },
  TLS: {
    display: 'TLS',
    value: 'tls'
  }
}
