<template>
  <li class="dropdown-submenu" :style="indent" v-if="canViewElement(nav)">
      <router-link v-if="nav.link" :to="'/dashboard' + nav.link" replace class="nav" title="nav.label">
          <font-awesome-icon v-if="nav.nodes && !showChildren" class='icon' icon="chevron-down" @click="toggleChildren"/>
          <font-awesome-icon v-if="nav.nodes && showChildren" class='icon' icon="chevron-up" @click="toggleChildren"/>
          <font-awesome-icon v-if="nav.icon" class='icon' :icon="nav.icon" />
          <span>{{ nav.label }}</span>
      </router-link>
      <span v-else @click="toggleChildren">
          <font-awesome-icon v-if="nav.nodes && !showChildren" class='icon' icon="chevron-down" />
          <font-awesome-icon v-if="nav.nodes && showChildren" class='icon' icon="chevron-up" />
          <font-awesome-icon v-if="nav.icon" class='icon' :icon="nav.icon" />
          <span>{{ nav.label }}</span>
      </span>
      <ul v-if="nav.nodes && showChildren">
        <NavigationElement v-for="(node, index) in nav.nodes" v-bind:key="index" :nav="node" :depth="_depth + 1"/>
      </ul>
  </li>
</template>
<script>
  import abilityTester from '@/config/abilityTester';
  export default {
    props: ['nav', 'depth'],
    name: 'NavigationElement',
    computed: {
      _depth() {
        return this.depth ? this.depth : 0;
      },
      indent() {
        return { 'margin-left': `${this._depth * 10}px`}
      },
    },
    data() {
      return {
        showChildren: true
      };
    },
    methods: {
      canViewElement(nav){
        if(!nav || !nav.permissions || !nav.permissions.subjectName){
          return true;
        }
        var action = nav.permissions.action ? nav.permissions.action : 'nav';
        var params = nav.permissions.getParams ? nav.permissions.getParams() : {};
        var field = nav.permissions.field ? nav.permissions.field : undefined;
        return abilityTester.can(action, nav.permissions.subjectName, params, field);
      },
      toggleChildren() {
        this.showChildren = !this.showChildren;
      }
    }
  }
</script>

<style scoped>
li {
    line-height: 30px;
    margin-left: -10px;
    padding-left: 10px;
    cursor: pointer !important;
    list-style-type: none;
}
a, span {
  color: #414042;
  display: inline;
}
.icon {
  width: 1.7em;
  text-align: center;
}
ul{
  margin: 0px;
  padding: 0px
}
</style>
