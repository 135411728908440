import SmtpProtocolEnum from '@/common/Enums/SmtpProtocol';

const CustomConfigFormFields = [
	{
		section_name: 'Email Information',
		fields: [
			{
				name: 'Email Server Host',
				path: 'local.smtp.host',
				placeholder: 'smtp.external.com',
			},
			{
				name: 'Email Server Protocol',
				path: 'local.smtp.protocol',
				type: 'select',
				options: SmtpProtocolEnum
			},
			{
				name: 'Email Server Username',
				path: 'local.smtp.username',
				placeholder: 'Username',
			},
			{
				name: 'Email Server Password',
				path: 'local.smtp.password',
				placeholder: 'Password',
				type: 'password'
			},
			{
				name: 'Support Email Address',
				path: 'local.email.support',
				placeholder: 'support@external.com'
			},
		]
	},
	{
		section_name: 'Billing Information',
		fields: [
			{
				name: 'Stripe API Key',
				path: 'billing.stripe.api_key',
			},
			{
				name: 'Stripe Publishable API Key',
				path: 'billing.stripe.publishable_api_key',
			},
			{
				name: 'Stripe Initial Credit Card Processing Fee Id',
				path: 'billing.stripe.cc_initial_processing_fee_id',
			},
			{
				name: 'Stripe Recurring Credit Card Processing Fee Id',
				path: 'billing.stripe.cc_recurring_processing_fee_id',
			},
			{
				name: 'Stripe Utility Fee Id',
				path: 'billing.stripe.utility_fee_id',
			},
		]
	},
	{
		section_name: 'Domain Information',
		fields: [
			{
				name: 'Openedge Domain',
				path: 'local.constants.domain',
				placeholder: 'internal.com'
			},
			{
				name: 'Provider Website URL',
				path: 'local.urls.external_site_url',
				placeholder: 'https://www.external.com'
			},
			{
				name: 'Support Ticket URL',
				path: 'local.urls.support_ticket_url',
				placeholder: 'https://support.external.com'
			},
			{
				name: 'Subscriber Portal Hostname',
				path: 'subscriber_portal_hostname',
				placeholder: 'portal'
			},
		]
	},
	{
		section_name: 'Networking',
		fields: [
			{
				name: 'IPv6 Operator Suffix',
				path: 'networks.ipv6_suffix',
				placeholder: '0000:0000:0000',
			},
		]
	},
	{
		section_name: 'Sentry',
		fields: [
			{
				name: 'Environment',
				path: 'sentry.environment',
				placeholder: 'Sentry Environment'
			},
		]
	},
	{
		section_name: 'Versioning',
		fields: [
			{
				default: false,
				name: 'Use New Subscriber Portal',
				path: 'local.constants.use_new_subscriber_portal',
				type: 'checkbox'
			},
		]
	},
]
export default CustomConfigFormFields;