<template>
  <form @submit.prevent="handleSubmit" id="user">
    <Form @submit.prevent="handleSubmit" :saveVisible='canUpdateUser()'>
      <b-row class='mb-5' >
        <FormInput className='user' fieldName='created_at' :value='user.created_at' :canUpdate='false' :singleLine='true'/>
        <FormInput className='user' fieldName='updated_at' :value='user.updated_at' :canUpdate='false' :singleLine='true'/>
      </b-row>
      <b-row class='mb-5' >
        <FormInput className='user' fieldName='username' v-model='user.username' :canUpdate='canUpdateUser("username")'/>
        <FormInput className='user' fieldName='password' v-model='user.password' :canUpdate='canUpdateUser("password")' type='password' autocomplete='new-password' :required='user.id ? false : true'/>
      </b-row>
      <b-row class='mb-5'>
        <FormSelect v-if='canReadField("status")' className='user' fieldName='status' v-model='user.status' :canUpdate='canUpdateUser("status")' :options='statuses'/>
        <FormSelect v-if='canReadField("role")' className='user' fieldName='role' v-model='user.role' :canUpdate='canUpdateUser("role")' :options='userRoles'/>
      </b-row>
      <div v-if='canUpdateUser("Contact")'>
        <div>
          <h3>Contact</h3>
        </div>
        <ContactEdit @UpdateContact='UpdateContact' :contact="user.Contact" className='user'></ContactEdit>
      </div>
    </Form>
  </form>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import ContactEdit from '@/components/contact/Edit';
  import Form from '@/components/form/Form';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import { mapMutations } from 'vuex';
  import { ADD_ERROR, ADD_SUCCESS } from '@/store/MutationTypes';
  import roleEnum from '@/common/Enums/UserRole';
  import rest from '@/common/tools/Rest';
  import statusEnum from '@/common/Enums/Status';
  import store from '@/store';

  export default {
    name: 'UserEdit',
    components: {
      ContactEdit,
      Form,
      FormInput,
      FormSelect
    },
    created(){
      this.setDefaults();
    },
    props: {
      user: {}
    },
    data() {
      return {
        statuses: statusEnum,
        userRoles: roleEnum
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canReadField: function(field) {
        return abilityTester.can('read', 'user', {user_id: this.user.id}, field);
      },
      canUpdateUser(field) {
        if(field === 'status' && this.user.id === store.state.session.user.id){
          return false;
        }
        return abilityTester.can('update', 'user', {user_id: this.user.id}, field);
      },
      getUserRoleDisplay(role){
        for(var k in this.userRoles){
          if(this.userRoles[k].value == role){
            return this.userRoles[k].display;
          }
        }
      },
      handleSubmit() {
        var data = JSON.parse(JSON.stringify(this.user));
        data['loading'] = 'Contact';
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('user', 'user', data, {'relations': {'Contact': 'user-contact'}}).then(user => {
          for(var k in user){
            this.user[k] = user[k];
          }
          this.UserChanged();
          this.alertSuccess('User Saved');
        }).catch(e => {
            this.error(rest.getErrorMessage(e));
        }).finally(() => {
            this.$emit('entrypoint_wait_hide');
        })
      },
      setDefaults(){
          if(!this.user.Contact){
            this.$set(this.user, 'Contact', {}); // This set is needed to make sure the state dropdown is updated correctly
          }
          if(!this.user.status){
            this.$set(this.user,'status', this.statuses.ACTIVE.value);
          }
      },
      UpdateContact(contact) {
        this.user.Contact = contact;
        this.UserChanged();
      },
      UserChanged(){
        this.$emit('UpdateUser', this.user);
      }
    },
    watch: {
      user: function(){
        this.setDefaults();
      }
    },
  }
</script>

<style scoped>
  .provider_search_results{
    position: absolute;
    height: auto;
    overflow: auto;
    z-index: 1;
    max-height: 75px;
  }
  .provider_search_results_row:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .user_permission_delete_btn{
    background-color: transparent;
    color: red;
  }
  .user_permission_delete_btn:hover, .user_permission_delete_btn:focus{
    border: 1px solid #ccc;
    border-radius: 4px;
    color: red;
  }
</style>
