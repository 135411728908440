<template>
  <b-col :sm='size'>
    <FormLabel :labelFor='className + "-" + fieldName' :label='_label' :required='required && canUpdate' :sameLine='singleLine'/>
    <input ref='input' type="checkbox" :id='className + "-" + fieldName' :name='fieldName' :class='small ? "checkbox" : "checkbox form-control"' @change='$emit("input", $event.target.checked)' :checked='value' :disabled='!canUpdate'/>
  </b-col>
</template>

<script>
  import FormLabel from '@/components/form/Label';

  export default {
    name: 'FormInput',
    components: {
      FormLabel
    },
    computed: {
      _label() {
        return this.label ? this.label : this.fieldName.replace(/_/g, ' ').replace(/-/g, ' ');
      }
    },
    data(){
      return {
      }
    },
    props: {
      canUpdate: {
        default: false,
        type: Boolean
      },
      className: String,
      fieldName: String,
      label: String,
      required: {
        default: true,
        type: Boolean
      },
      singleLine: {
        default: false,
        type: Boolean
      },
      size: {
        default: '6',
        type: String
      },
      small: {
        default: true,
        type: Boolean
      },
      value: {
        default: false,
        type: Boolean
      }
    },
    methods: {
      focus() {
        this.$refs.input.focus();
      },
    }
  }
</script>
<style scoped>
  .checkbox, .checkbox:focus, .checkbox:hover{
    border: none;
    box-shadow: none;
    outline: none;
  }
</style>
