import stateTypes from '@/common/Enums/StateType';
import countries from '@/common/Enums/Country';

var canada = {
  ALBERTA: {
    display: 'Alberta',
    type: stateTypes.PROVINCE.value,
    value: 'AB'
  },
  BRITISH_COLUMBIA: {
    display: 'British Columbia',
    type: stateTypes.PROVINCE.value,
    value: 'BC'
  },
  MANITOBA: {
    display: 'Manitoba',
    type: stateTypes.PROVINCE.value,
    value: 'MB'
  },
  NEW_BRUNSWICK: {
    display: 'New Brunswick',
    type: stateTypes.PROVINCE.value,
    value: 'NB'
  },
  NEWFOUNDLAND_LABRADOR: {
    display: 'Newfoundland and Labrador',
    type: stateTypes.PROVINCE.value,
    value: 'NL'
  },
  NOVA_SCOTIA: {
    display: 'Nova Scotia',
    type: stateTypes.PROVINCE.value,
    value: 'NS'
  },
  ONTARIO: {
    display: 'Ontario',
    type: stateTypes.PROVINCE.value,
    value: 'ON'
  },
  PRINCE_EDWARD: {
    display: 'Prince Edward Island',
    type: stateTypes.PROVINCE.value,
    value: 'PE'
  },
  QUEBEC: {
    display: 'Quebec',
    type: stateTypes.PROVINCE.value,
    value: 'QC'
  },
  SASKATCHEWAN: {
    display: 'Saskatchewan',
    type: stateTypes.PROVINCE.value,
    value: 'SK'
  },
  NORTHWEST_TERRITORIES: {
    display: 'Northwest Territories',
    type: stateTypes.TERRITORY.value,
    value: 'NT'
  },
  NUNAVUT: {
    display: 'Nunavut',
    type: stateTypes.TERRITORY.value,
    value: 'NU'
  },
  YUKON: {
    display: 'Yukon',
    type: stateTypes.TERRITORY.value,
    value: 'YT'
  },
}

var us = {
  ALABAMA: {
    display: 'Alabama',
    type: stateTypes.STATE.value,
    value: 'AL'
  },
  ALASKA: {
    display: 'Alaska',
    type: stateTypes.STATE.value,
    value: 'AK'
  },
  ARIZONA: {
    display: 'Arizona',
    type: stateTypes.STATE.value,
    value: 'AZ'
  },
  ARKANSAS: {
    display: 'Arkansas',
    type: stateTypes.STATE.value,
    value: 'AR'
  },
  CALIFORNIA: {
    display: 'California',
    type: stateTypes.STATE.value,
    value: 'CA'
  },
  COLORADO: {
    display: 'Colorado',
    type: stateTypes.STATE.value,
    value: 'CO'
  },
  CONNECTICUT: {
    display: 'Connecticut',
    type: stateTypes.STATE.value,
    value: 'CT'
  },
  DELAWARE: {
    display: 'Delaware',
    type: stateTypes.STATE.value,
    value: 'DE'
  },
  DC: {
    display: 'District Of Columbia',
    type: stateTypes.STATE.value,
    value: 'DC'
  },
  FLORIDA: {
    display: 'Florida',
    type: stateTypes.STATE.value,
    value: 'FL'
  },
  GEORGIA: {
    display: 'Georgia',
    type: stateTypes.STATE.value,
    value: 'GA'
  },
  HAWAII: {
    display: 'Hawaii',
    type: stateTypes.STATE.value,
    value: 'HI'
  },
  IDAHO: {
    display: 'Idaho',
    type: stateTypes.STATE.value,
    value: 'ID'
  },
  ILLINOIS: {
    display: 'Illinois',
    type: stateTypes.STATE.value,
    value: 'IL'
  },
  INDIANA: {
    display: 'Indiana',
    type: stateTypes.STATE.value,
    value: 'IN'
  },
  IOWA: {
    display: 'Iowa',
    type: stateTypes.STATE.value,
    value: 'IA'
  },
  KANSAS: {
    display: 'Kansas',
    type: stateTypes.STATE.value,
    value: 'KS'
  },
  KENTUCKY: {
    display: 'Kentucky',
    type: stateTypes.STATE.value,
    value: 'KY'
  },
  LOUISIANA: {
    display: 'Louisiana',
    type: stateTypes.STATE.value,
    value: 'LA'
  },
  MAINE: {
    display: 'Maine',
    type: stateTypes.STATE.value,
    value: 'ME'
  },
  MARYLAND: {
    display: 'Maryland',
    type: stateTypes.STATE.value,
    value: 'MD'
  },
  MASSACHUSETTS: {
    display: 'Massachusetts',
    type: stateTypes.STATE.value,
    value: 'MA'
  },
  MICHIGAN: {
    display: 'Michigan',
    type: stateTypes.STATE.value,
    value: 'MI'
  },
  MINNESOTA: {
    display: 'Minnesota',
    type: stateTypes.STATE.value,
    value: 'MN'
  },
  MISSISSIPPI: {
    display: 'Mississippi',
    type: stateTypes.STATE.value,
    value: 'MS'
  },
  MISSOURI: {
    display: 'Missouri',
    type: stateTypes.STATE.value,
    value: 'MO'
  },
  MONTANA: {
    display: 'Montana',
    type: stateTypes.STATE.value,
    value: 'MT'
  },
  NEBRASKA: {
    display: 'Nebraska',
    type: stateTypes.STATE.value,
    value: 'NE'
  },
  NEVADA: {
    display: 'Nevada',
    type: stateTypes.STATE.value,
    value: 'NV'
  },
  NEW_HAMPSHIRE: {
    display: 'New Hampshire',
    type: stateTypes.STATE.value,
    value: 'NH'
  },
  NEW_JERSEY: {
    display: 'New Jersey',
    type: stateTypes.STATE.value,
    value: 'NJ'
  },
  NEW_MEXICO: {
    display: 'New Mexico',
    type: stateTypes.STATE.value,
    value: 'NM'
  },
  NEW_YORK: {
    display: 'New York',
    type: stateTypes.STATE.value,
    value: 'NY'
  },
  NORTH_CAROLINA: {
    display: 'North Carolina',
    type: stateTypes.STATE.value,
    value: 'NC'
  },
  NORTH_DAKOTA: {
    display: 'North Dakota',
    type: stateTypes.STATE.value,
    value: 'ND'
  },
  OHIO: {
    display: 'Ohio',
    type: stateTypes.STATE.value,
    value: 'OH'
  },
  OKLAHOMA: {
    display: 'Oklahoma',
    type: stateTypes.STATE.value,
    value: 'OK'
  },
  OREGON: {
    display: 'Oregon',
    type: stateTypes.STATE.value,
    value: 'OR'
  },
  PENNSYLVANIA: {
    display: 'Pennsylvania',
    type: stateTypes.STATE.value,
    value: 'PA'
  },
  RHODE_ISLAND: {
    display: 'Rhode Island',
    type: stateTypes.STATE.value,
    value: 'RI'
  },
  SOUTH_CAROLINA: {
    display: 'South Carolina',
    type: stateTypes.STATE.value,
    value: 'SC'
  },
  SOUTH_DAKOTA: {
    display: 'South Dakota',
    type: stateTypes.STATE.value,
    value: 'SD'
  },
  TENNESSEE: {
    display: 'Tennessee',
    type: stateTypes.STATE.value,
    value: 'TN'
  },
  TEXAS: {
    display: 'Texas',
    type: stateTypes.STATE.value,
    value: 'TX'
  },
  UTAH: {
    display: 'Utah',
    type: stateTypes.STATE.value,
    value: 'UT'
  },
  VERMONT: {
    display: 'Vermont',
    type: stateTypes.STATE.value,
    value: 'VT'
  },
  VIRGINIA: {
    display: 'Virginia',
    type: stateTypes.STATE.value,
    value: 'VA'
  },
  WASHINGTON: {
    display: 'Washington',
    type: stateTypes.STATE.value,
    value: 'WA'
  },
  WEST_VIRGINIA: {
    display: 'West Virginia',
    type: stateTypes.STATE.value,
    value: 'WV'
  },
  WISCONSIN: {
    display: 'Wisconsin',
    type: stateTypes.STATE.value,
    value: 'WI'
  },
  WYOMING: {
    display: 'Wyoming',
    type: stateTypes.STATE.value,
    value: 'WY'
  },
}
var stateEnum = {};
stateEnum[countries.CANADA.value] = canada;
stateEnum[countries.US.value] = us;
export default stateEnum;
