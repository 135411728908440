<template>
  <b-col :sm='size'>
    <FormLabel :labelFor='className + "-" + fieldName' :label='_label' :required='required && canUpdate' :sameLine='singleLine'/>
    <textarea v-if='canUpdate' ref='input' :id='className + "-" + fieldName' :name='fieldName' class='form-control ' :value="value" :placeholder="placeholder ? placeholder : label" @input='$emit("input", $event.target.value)' @change='$emit("change", $event.target.value)' :rows='rows' :required='required'/>
    <span v-else>
      {{value}}
    </span>
  </b-col>
</template>

<script>
  import FormLabel from '@/components/form/Label';

  export default {
    name: 'TextArea',
    components: {
      FormLabel
    },
    computed: {
      _label() {
        return this.label ? this.label : this.fieldName.replace(/_/g, ' ').replace(/-/g, ' ');
      }
    },
    data(){
      return {
      }
    },
    props: {
        canUpdate: {
          default: false,
          type: Boolean
        },
        className: String,
        fieldName: String,
        label: String,
        placeholder: String,
        required: {
          default: true,
          type: Boolean
        },
        rows: {
          default: '3',
          type: String
        },
        singleLine: {
          default: false,
          type: Boolean
        },
        size: {
          default: '6',
          type: String
        },
        value: String
    },
  }
</script>
