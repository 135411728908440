<template>
  <div id="nav-sidebar" class="nav">
    <ul>
      <NavElement ref='navElements' v-for="(nav, index) in navigation" v-bind:key="index" :nav="nav"></NavElement>
    </ul>
  </div>
</template>
<script>
  import NavElement from '@/components/navigation/Element.vue';

  export default {
    name: 'NavigationSidebar',
    components: {
      NavElement
    },
    data() {
      return {
        navigation:[
          {
            icon: 'cubes',
            label: 'Providers',
            link: '/provider/list',
            permissions: {
              field: 'provider',
              subjectName: 'All'
            }
          },
          {
            icon: 'users',
            label: 'Users',
            link: '/user/list',
            permissions: {
              field: 'user',
              subjectName: 'All'
            }
          },
          {
            label: 'Manufacturer',
            link: '/manufacturer/list',
            permissions: {
              field: 'manufacturer',
              subjectName: 'All'
            }
          },
          {
            label: 'Reports',
            nodes: [
              {
                icon: 'file-invoice-dollar',
                label: 'Billing',
                link: '/provider/billing',
                permissions: {
                  field: 'provider-billing',
                  subjectName: 'All'
                }
              },
            ],
            permissions: {
              field: 'report',
              subjectName: 'All'
            }
          },
          {
            label: 'Signature',
            link: '/signature/list',
            permissions: {
              field: 'signature',
              subjectName: 'All'
            }
          },
          {
            label: 'Version',
            link: '/version/list',
            permissions: {
              field: 'version',
              subjectName: 'All'
            }
          }
        ]
      };
    },
  }
</script>

<style scoped>
  #nav-sidebar {
    background: #f1f2f2;
    border-right: solid 1px #d1d3d4;
    height: 100%;
    left: 17vmax;
    margin-left: -17vmax;
    overflow-y: auto;
    position: fixed;
    top: 50px;
    width: 17vmax;
    z-index: 1000;
  }
  #nav-sidebar ul{
    margin: 0px;
    padding: 0px
  }
</style>
