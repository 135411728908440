export default {
  ALL: {
    display: 'All',
    value: 'ALL'
  },
  READ_ONLY: {
    display: 'Read Only',
    value: 'READ_ONLY'
  },
  SERVICE: {
    display: 'Service',
    value: 'SERVICE'
  },
  TRANSPORT: {
    display: 'Transport',
    value: 'TRANSPORT'
  }
}
