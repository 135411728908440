<template>
  <div>
    <form>
        <div>
          <b-row class='mb-5' >
            <FormInput className='product' fieldName='created_at' :value='product.created_at' :canUpdate='false' :singleLine='true'/>
            <FormInput className='product' fieldName='updated_at' :value='product.updated_at' :canUpdate='false' :singleLine='true'/>
          </b-row>
          <b-row class='mb-5' >
            <FormInput v-if='canReadField("acronym")' className='product' fieldName='acronym' v-model='product.acronym' :canUpdate='canUpdateProduct("acronym")' :required='_isNew'/>
            <FormInput v-if='canReadField("hardware")' className='product' fieldName='hardware' v-model='product.hardware' :canUpdate='canUpdateProduct("hardware")' :required='_isNew'/>
          </b-row>
          <b-row class='mb-5' >
            <FormInput v-if='canReadField("make")' className='product' fieldName='make' v-model='product.make' :canUpdate='canUpdateProduct("make")' :required='_isNew'/>
            <FormInput v-if='canReadField("model")' className='product' fieldName='model' v-model='product.model' :canUpdate='canUpdateProduct("model")' :required='_isNew'/>
          </b-row>
          <b-row class='mb-5' >
            <FormCheckBox v-if='canReadField("has_api")' className='product' fieldName='has_api' v-model='product.has_api' :canUpdate='canUpdateProduct("has_api")'/>
            <FormCheckBox v-if='canReadField("ssl_enabled")' className='product' fieldName='ssl_enabled' v-model='product.ssl_enabled' :canUpdate='canUpdateProduct("ssl_enabled")'/>
          </b-row>
          <b-row class='mb-5' >
            <FormSelect v-if='canReadField("type")' className='product' fieldName='type' v-model='product.type' :canUpdate='canUpdateProduct("type")' :options='productTypes'/>
            <FormSelect v-if='canReadField("status")' className='product' fieldName='status' v-model='product.status' :canUpdate='canUpdateProduct("status")' :options='statuses'/>
          </b-row>
          <b-row class='mb-5' >
            <FormText v-if='canReadField("description")' className='product' fieldName='description' v-model='product.description' :canUpdate='canUpdateProduct("description")'/>
            <FormInput v-if='canReadField("part_number")' className='product' fieldName='Part number' v-model='product.part_number' :canUpdate='canUpdateProduct("part_number")'/>
          </b-row>
        </div>
    </form>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import FormText from '@/components/form/TextArea';
  import FormCheckBox from '@/components/form/CheckBox';
  import { mapMutations } from 'vuex';
  import rest from '@/common/tools/Rest';
  import statusesEnum from '@/common/Enums/Status';
  import productsEnum from '@/common/Enums/ProductType';

  export default {
    name: 'ProductEdit',
    components:{
      FormInput,
      FormSelect,
      FormText,
      FormCheckBox,
    },
    computed: {
      _isNew(){
        return !(this.prodcut && this.product.id);
      }
    },
    created(){
        this.setDefaults();
    },
    data() {
      return {
        statuses: statusesEnum,
        productTypes: productsEnum,
      }
    },
    props: {
      product: {},
      manufacturer_id: {
        type: String,
        required: true,
        validator: function(value) {
          return value ? true : false;
        }
      }
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      canReadField: function(field) {
        return abilityTester.can('read', 'product', {product_id: this.product.id}, field);
      },
      canUpdateProduct: function(field) {
        return abilityTester.can('update', 'product', {product_id: this.product.id}, field);
      },
      save() {
        var data = JSON.parse(JSON.stringify(this.product));
        data['manufacturer_id'] = this.manufacturer_id;
        var saveProduct = (data) => {
          this.$emit('entrypoint_wait_show','Saving...')
          rest.save('product/', 'product', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.product, k, val);
            }
            this.$emit('entrypoint_wait_hide');
            this.alertSuccess('Product Saved');
            this.$emit('saved');
          }).catch(e => {
              this.$emit('entrypoint_wait_hide');
              this.error(rest.getErrorMessage(e));
          })
        }
        saveProduct(data);
      },
      setDefaults(){
        if(!this.product.status){
          this.$set(this.product,'status', this.statuses.ACTIVE.value);
        }
      },
    },
    watch: {
      product: function(){
        this.setDefaults();
      }
    },
  }
</script>
