<template>
  <div>
    <AlertContainer/>
    <form @submit.prevent="handleSubmit" id="login">
        <div class="page-header">
          <h1>NorthStar Portal Sign In</h1>
        </div>
        <div class="form">
          <b-row>
            <FormInput size='12' ref='username' fieldName='username' v-model='input.username' :canUpdate='true'/>
          </b-row>
          <b-row>
            <FormInput size='12' fieldName='password' v-model='input.password' :canUpdate='true' type='password' autocomplete='current-password'/>
          </b-row>
          <b-row>
            <b-col sm='12'>
              <button type="submit" class='btn btn-lg btn-primary btn-block'>Login</button>
            </b-col>
          </b-row>
        </div>
    </form>
  </div>
</template>

<script>
  import rest from '@/common/tools/Rest';
  import AlertContainer from '@/components/alerts/Container';
  import FormInput from '@/components/form/Input';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR, SET_USER} from '@/store/MutationTypes';

  export default {
    name: 'Login',
    components: {
      AlertContainer,
      FormInput
    },
    data() {
      return {
        input: {
          username: "",
          password: "",
        },
      }
    },
    mounted(){
      this.$refs.username.focus();
    },
    methods: {
      ...mapMutations('session', {
        setUser: SET_USER,
      }),
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      handleSubmit() {
        var data ={
          username: this.input.username,
          password: this.input.password
        };
        rest.post('user/login?loading=Contact', 'user', data, '', false).then((user) => {
          user = user.user ? user.user : user
          this.setUser(user);
          this.$router.replace({name: 'dashboard' });
        }).catch(e => {
            this.error(rest.getErrorMessage(e));
        })
      },
    }
  }
</script>

<style scoped>
    #login {
        width: 500px;
        margin: auto;
        overflow: auto;
        margin-bottom: 60px;
      }

    #login h1 {
      font-weight: 500;
    }
    .form {
      max-width: 300px;
      padding: 15px;
      margin: 0 auto;
    }
  .page-header{
    text-align: center;
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
  .row{
    margin-top: 20px;
  }
</style>
