const CustomConfigFormFields = [
	{
		section_name: 'Provider Information',
		fields: [
			{
				name: 'Openedge Domain',
				path: 'local.constants.domain',
				placeholder: 'internal.com'
			},
			{
				name: 'Support Email Address',
				path: 'local.email.support',
				placeholder: 'support@external.com'
			},
			{
				name: 'Subscriber Portal Hostname',
				path: 'subscriber_portal_hostname',
				placeholder: 'portal'
			},
		]
	},
	{
		section_name: 'Networking',
		fields: [
			{
				name: 'IPv6 Operator Suffix',
				path: 'networks.ipv6_suffix',
				placeholder: '0000:0000:0000',
			},
		]
	},
	{
		section_name: 'Sentry',
		fields: [
			{
				name: 'Environment',
				path: 'sentry.environment',
				placeholder: 'Sentry Environment'
			},
		]
	},
]
export default CustomConfigFormFields;