
<script>
import { BModal } from 'bootstrap-vue'

  export default {
    name: 'Modal',
    extends: BModal,
    computed: {
      modalClasses() {
          var classes = this._BModalComputedModalClasses();
          if(this.isVisible && !this.isTransitioning){
            // fix issue with fade not working
            classes.push('in');
          }
          return classes;
      }
    },
    created() {
      var emitEvent = this.emitEvent;
      this.emitEvent = function(bvEvt){
        if(!this.noFade && bvEvt.type === 'shown'){
          // fix issue with fade not working
          bvEvt.target.classList.add('in');
        }
        emitEvent(bvEvt);
      }
    },
    methods: {
      _BModalComputedModalClasses: BModal.extendOptions.computed.modalClasses
    }
  }
</script>
